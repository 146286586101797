/* eslint-disable import/no-cycle */
import { useBooleanState } from '@clutch/hooks';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { createContext, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { flowToFormatterMap } from '../../../eventFormatter/utils';
import VehicleState from '../../../helpers/vehicle-state';
import useFinanceCalculator from '../../../hooks/useFinanceCalculator';
import { FINANCING_YEAR_LOW_CUT_OFF, MINIMUM_LOAN_AMOUNT, ROUTES } from '../../../static';
import { AnalyticsContext } from '../../analytics';
import { AuthContext, LoginSignupModalContext } from '../../index';
import { VehicleDetailsContext } from '../vehicleDetails';
import { isFinancingAllowed } from 'src/helpers';

export const FinancingContext = createContext();
// TODO: If we get rid of the apply for financing flow when we role checkout out
// We can remove this context all together and move the is isFinancingAllowed function to
// financeCalculatorHook
const FinancingProvider = ({ children, history }) => {
  const { vehicle, isLoading: vehicleIsLoading } = useContext(VehicleDetailsContext);
  const { isAuthenticated, user } = useContext(AuthContext);
  const { clutchDataLayer } = useContext(AnalyticsContext);
  const loginSignupModalContext = useContext(LoginSignupModalContext);
  const financeCalculator = useFinanceCalculator({ isFinancing: !user?.isDealer });
  const isFinancingAllowedState = useBooleanState({ initialState: false });

  const getFinancingRoute = () => {
    if (!vehicleIsLoading) {
      return ROUTES.FINANCE_APPLICATION.replace(':vehicleId', R.path(['id'], vehicle));
    }
    return '';
  };

  const startFinancing = async () => {
    if (isAuthenticated) {
      history.push(getFinancingRoute());
    } else {
      loginSignupModalContext.onModalOpen({
        shouldRedirectOnSignIn: true,
        redirectUrl: getFinancingRoute(),
      });
    }
  };

  const trackEvent = ({ event, formDetails }) => {
    try {
      const { name, details, action, payload, nonInteraction, isCoApplicant = false } = event;

      clutchDataLayer.track(name, {
        details,
        action,
        flow: 'finance',
        payload,
        nonInteraction,

        ...flowToFormatterMap.finance({
          vehicle,
          formDetails,
          isAuthenticated,
          isCoApplicant,
        }),
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (vehicle.id && financeCalculator.vehicleSellingPrice && !vehicleIsLoading) {
      if (
        isFinancingAllowed({
          isDealer: user?.isDealer,
          vehicle,
          totalLoanAmount: financeCalculator.totalLoanAmount,
        })
      ) {
        isFinancingAllowedState.setTrue();
      } else {
        isFinancingAllowedState.setFalse();
      }
    }
  }, [vehicle.id, financeCalculator.vehicleSellingPrice, user?.isDealer, vehicleIsLoading]);

  return (
    <FinancingContext.Provider
      value={{
        financeCalculator,
        startFinancing,
        isFinancingAllowed: isFinancingAllowedState.value,
        trackEvent,
      }}
    >
      {children}
    </FinancingContext.Provider>
  );
};

FinancingProvider.propTypes = {
  children: PropTypes.any.isRequired,
  history: PropTypes.object.isRequired,
};

const WrappedFinancingProvider = withRouter(FinancingProvider);

export { WrappedFinancingProvider as FinancingProvider };
