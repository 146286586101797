import { matchPath, useHistory } from 'react-router';

import { FILTER_KEY_MAP } from '@clutch/common/constants/filter';
import { locations } from '../components/Pages/Landing/utils';
import { ROUTES } from '../static';

const findFiltersInUrlParam = ({ lookupState = {} }) => {
  const history = useHistory();
  const matchShowroomPath = matchPath(history.location.pathname, {
    path: ROUTES.SHOWROOM,
    exact: true,
  });
  const { primaryFilters, secondaryFilters } = matchShowroomPath?.params || {};
  const { MAKES, MODELS, BODY_STYLES, COLORS, FUEL_TYPES } = FILTER_KEY_MAP;

  const findFilterInParam = ({ filterKey, primaryFiltersArray = [], secondaryFiltersArray = [] }) =>
    (lookupState[filterKey] || []).find(lookupValue => {
      if (!lookupValue?.name) {
        return false;
      }

      const formattedLookupUrl = lookupValue.name?.replace(/\s/g, '-')?.toLowerCase();

      return primaryFiltersArray.includes(formattedLookupUrl) || secondaryFiltersArray.includes(formattedLookupUrl);
    });

  const urlParamFilters = {};
  // To parse filters in the url params, we find matching substrings sorted by string length,
  // beginning with color and fuel type.
  // This is because conflicts can occur when smaller substrings exist within the actual
  // substrings we want. After proper substring is found, we remove the substring from the
  // main string to avoid further conflicts.
  let secondaryFilterString = secondaryFilters;
  let primaryFilterString = primaryFilters;

  const foundLocation = locations.find(location => primaryFilters?.includes(location.path) || secondaryFilters?.includes(location.path));

  if (foundLocation) {
    const locationString = `in-${foundLocation.city.replace(/\s/g, '-').toLowerCase()}`;

    if (secondaryFilters) {
      secondaryFilterString = secondaryFilterString.replace(locationString, '');
    } else {
      primaryFilterString = primaryFilterString.replace(locationString, '');
    }

    urlParamFilters.location = foundLocation;
  }

  // As make, model and body style are combined into one string with a hyphen separating
  // attributes as well as representing white space, we search from body style first to
  // make last. Because any of these can be more than one word, hence conflicting with the
  // hyphens separating attributes, we search for a exact match starting with the longest
  // possible option first and working our way to the shorter option, and removing from the
  // array of options when a matching filter is found.

  const primaryFiltersArray = (primaryFilterString || '').split('-');

  // Find Body Style
  // Body Style is never more than two separate words, and is at the end of the
  // params, so start with the last two items in the params array
  const foundBodyStyle = (lookupState[BODY_STYLES] || []).find(bodyStyle => {
    const nameLength = bodyStyle.name.split(' ')?.length;
    return (
      bodyStyle.name.replace(/\s/g, '-').toLowerCase() === primaryFiltersArray.slice(primaryFiltersArray.length - nameLength).join('-')
    );
  });

  if (foundBodyStyle) {
    const length = foundBodyStyle.name?.split(' ')?.length;
    if (length) {
      primaryFiltersArray.splice(primaryFiltersArray.length - length);
      urlParamFilters[BODY_STYLES] = foundBodyStyle;
    }
  }

  // Find Model, then Make
  if (primaryFiltersArray.length > 0) {
    const foundModel = (lookupState[MODELS] || []).find(model => {
      // certain model names have a hyphen to act as a space. (eg Mercedes-Benz A-Class)
      // regex /[\s-]/ will split on both spaces and hyphens (" ", "-")
      const nameLength = model.name.split(/[\s-]/).length;

      // math max is used with the assumption that first item in the primaryFiltersArray must always be the make
      // so item at index 0 in paramFilterArray is not part of the model
      return (
        model.name.replace(/\s/g, '-').toLowerCase() ===
        primaryFiltersArray.slice(Math.max(1, primaryFiltersArray.length - nameLength)).join('-')
      );
    });

    if (foundModel) {
      primaryFiltersArray.splice(primaryFiltersArray.length - foundModel.name.split(/[\s-]/).length);
      urlParamFilters[MODELS] = foundModel;
      urlParamFilters[MAKES] = (lookupState[MAKES] || []).find(
        make => make.name.replace(/\s/g, '-')?.toLowerCase() === foundModel.makeName.toLowerCase(),
      );
    }
  }

  // Find Make if Model is not included
  if (primaryFiltersArray.length && !urlParamFilters[MAKES]) {
    const foundMake = (lookupState[MAKES] || []).find(
      make => make.name?.replace(/\s/g, '-')?.toLowerCase() === primaryFiltersArray.join('-'),
    );
    if (foundMake) {
      urlParamFilters[MAKES] = foundMake;
    }
  }

  const secondaryFiltersArray = (secondaryFilterString || '').split('-');
  [FUEL_TYPES, COLORS].forEach(filterKey => {
    const foundFilter = findFilterInParam({
      filterKey,
      primaryFiltersArray,
      secondaryFiltersArray,
    });
    if (foundFilter) {
      urlParamFilters[filterKey] = foundFilter;
    }
  });

  return urlParamFilters;
};

export default findFiltersInUrlParam;
