import dayjs from 'dayjs';
import { z } from 'zod';

export const zodDateString = z
  .string()
  .refine(value => dayjs(value).isValid())
  .transform(value => dayjs(value).toDate())
  .or(z.date());

export const zodPostalCode = z
  .string()
  .regex(/^[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d$/)
  .transform(value => value.toUpperCase());

export const zodPhoneNumber = z
  .string()
  .min(1, 'Required')
  .regex(/^[0-9]{10}$/);
