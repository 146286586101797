import { Stack, Typography } from '@mui/material';
import type { ReactNode } from 'react';

export const ControllerContainer = ({ label, children }: { label?: string; children: ReactNode }) => (
  <Stack width={1} spacing={1}>
    {label && (
      <Typography variant="body1" sx={{ display: 'inline' }}>
        {label}
      </Typography>
    )}
    {children}
  </Stack>
);
