import { formatPrice } from '@clutch/helpers';
import { Stack, Typography } from '@mui/material';
import { getVehicleSellingPrice } from 'src/containers/VehicleDetails/components/VehicleOverview/components/PriceRow/utils';
import { VehicleInformationProps } from '../VehicleInformation';

export const CashPrice = ({ vehicle, isSale = false, previousPrice }: VehicleInformationProps) => {
  const price = getVehicleSellingPrice(vehicle);
  const showDiscount = isSale && !!previousPrice;

  return (
    <Stack spacing={1} direction="row" alignItems="center">
      {showDiscount && <Typography sx={{ textDecoration: 'line-through' }}>{formatPrice(previousPrice)}</Typography>}
      <Typography variant="subtitle2">{price ? formatPrice(price) : 'Price pending'}</Typography>
    </Stack>
  );
};
