import type { ApiCallback, ApiObject, ApiOptions, RudderAnalytics } from '@rudderstack/analytics-js';
import { useFlags } from 'launchdarkly-react-client-sdk';
import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import queryString from 'query-string';
import * as R from 'ramda';

import type { Flags } from 'src/constants';
import { AuthContext } from 'src/contexts';

import { useRudderStackAnalytics } from '../../hooks';

type AnalyticsContextType = {
  clutchDataLayer: {
    page: RudderAnalytics['page'];
    track: RudderAnalytics['track'];
  };
};
export const AnalyticsContext = createContext<AnalyticsContextType>({} as AnalyticsContextType);

type AnalyticsProviderProps = {
  children: ReactNode;
};

type Nullable<T> = T | null;

type PageFnType = (...args: Parameters<RudderAnalytics['page']>) => void;
type TrackFnType = (...args: Parameters<RudderAnalytics['track']>) => void;
export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const [pendingEvents, setPendingEvents] = useState<
    { event: string; properties: ApiCallback | Nullable<ApiObject> | undefined; callback: ApiCallback | Nullable<ApiOptions> | undefined }[]
  >([]);
  const [process, setProcess] = useState(false);

  const { isAuthenticated, isAuthenticating, user } = useContext(AuthContext);
  const flags = useFlags<Flags>();
  const analytics = useRudderStackAnalytics();

  const getAllProperties = (properties: ApiCallback | ApiObject | ApiOptions | null | undefined = {}) => {
    const { googleAdClicks, ...restUser } = user;
    const localGoogleClicks = JSON.parse(localStorage.getItem('gclid') || '{}');

    const search = window?.location?.search;
    const utmProperties: Record<string, string> = R.pick(
      ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'],
      search ? queryString.parse(search) : {},
    );

    return {
      user: { ...restUser },
      googleAdClicks: {
        ...googleAdClicks,
        gclid: googleAdClicks?.gclid || localGoogleClicks?.gclid,
      },
      isAuthenticated,
      flags: { ...flags }, // shallow copy here is okay
      ...utmProperties,
      ...properties,
    };
  };
  const page: PageFnType = (category, name, properties, callback) => {
    analytics?.page(category, name, getAllProperties(properties), callback);
  };

  const track: TrackFnType = (event, properties, callback) => {
    setPendingEvents(prev => [...prev, { event, properties, callback }]);
  };

  useEffect(() => {
    if (!isAuthenticated && isAuthenticating) {
      setProcess(false);
    } else {
      setProcess(true);
    }
  }, [isAuthenticated, isAuthenticating]);

  useEffect(() => {
    if (process && pendingEvents.length > 0) {
      const { event, properties, callback } = pendingEvents[0];
      analytics?.track(event, getAllProperties(properties), callback);
      setPendingEvents(prev => prev.slice(1));
    }
  }, [process, pendingEvents]);

  return (
    <AnalyticsContext.Provider
      value={{
        clutchDataLayer: {
          page,
          track,
        },
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
