import * as Sentry from '@sentry/browser';
import { useContext } from 'react';

import { AuthContext, LoginSignupModalContext } from 'src/contexts';
import { ROUTES } from 'src/static';
import { CLUTCH_BLOG_URL } from 'src/static/routes';

enum UserPages {
  MY_ACCOUNT = 'My account',
  MY_ORDERS = 'My orders',
  MY_CARS = 'My cars',
  SIGN_OUT = 'Sign out',
}

enum GeneralPages {
  SHOP_CARS = 'Shop Cars',
  SELL_OR_TRADE = 'Sell or Trade',
  FINANCING = 'Financing',
  MORE = 'More',
}

type NavbarHook = {
  getDesktopMenuItems: () => NavMenuOption[];
  getDesktopUserItems: () => NavMenuOption[];
  getMobileMenuItems: () => NavMenuOption[];
  openLoginModal: () => void;
};

export type NavMenuOption = {
  label: string;
  subLabel?: string;
  mobileSubLabel?: string;
  link?: string;
  hideOnMobile?: boolean;
  type: 'dropdown' | 'external' | 'function' | 'internal';
  dropdown?: NavMenuOption[];
  onClick?: () => void;
};

export const useNavbar = (): NavbarHook => {
  const authContext = useContext(AuthContext);
  const { onModalOpen, loginStep } = useContext(LoginSignupModalContext);

  const openLoginModal = () => {
    Sentry.addBreadcrumb({
      category: 'auth',
      message: 'User clicked the LoginSignUpButton whilst logged out, so opening login modal',
      level: Sentry.Severity.Info,
    });
    onModalOpen();
    loginStep();
  };

  const userMenuItems: Record<UserPages, NavMenuOption> = {
    [UserPages.MY_ACCOUNT]: {
      label: UserPages.MY_ACCOUNT,
      link: ROUTES.MY_DASHBOARD[1],
      type: 'internal',
    },
    [UserPages.MY_ORDERS]: {
      label: UserPages.MY_ORDERS,
      subLabel: 'View pending orders',
      mobileSubLabel: 'View pending orders',
      link: ROUTES.MY_DASHBOARD[5],
      type: 'internal',
    },
    [UserPages.MY_CARS]: {
      label: UserPages.MY_CARS,
      subLabel: 'Manage offers on my cars',
      mobileSubLabel: 'View and manage offers on my cars',
      link: ROUTES.MY_DASHBOARD[2],
      type: 'internal',
    },
    [UserPages.SIGN_OUT]: {
      label: 'Sign out',
      onClick: () => {
        authContext.logout(false);
      },
      type: 'function',
    },
  };

  const generalMenuItems: Record<GeneralPages, NavMenuOption> = {
    [GeneralPages.SHOP_CARS]: {
      label: GeneralPages.SHOP_CARS,
      link: ROUTES.SHOWROOM[0],
      type: 'internal',
      mobileSubLabel: 'Hundreds of cars to pick from',
    },
    [GeneralPages.SELL_OR_TRADE]: {
      label: GeneralPages.SELL_OR_TRADE,
      link: ROUTES.PRIVATE_PURCHASE,
      type: 'internal',
      mobileSubLabel: 'Get a firm offer within minutes',
    },
    [GeneralPages.FINANCING]: {
      label: GeneralPages.FINANCING,
      type: 'dropdown',
      dropdown: [
        { label: 'How financing works', link: ROUTES.AUTO_FINANCING.ROOT, type: 'internal' },
        { label: 'Car loan calculator', link: ROUTES.AUTO_FINANCING.CAR_LOAN_CALCULATOR, type: 'internal' },
      ],
    },
    [GeneralPages.MORE]: {
      label: GeneralPages.MORE,
      type: 'dropdown',
      dropdown: [
        { label: 'About', link: ROUTES.ABOUT, type: 'internal' },
        { label: 'Protection plans', link: ROUTES.PROTECTION_PLANS, type: 'internal' },
        { label: 'FAQ', link: ROUTES.FAQ, type: 'internal' },
        { label: 'Contact us', link: ROUTES.CONTACT, type: 'internal', hideOnMobile: true },
        { label: 'Blog', link: CLUTCH_BLOG_URL, type: 'external' },
      ],
    },
  };

  const getDesktopMenuItems = (): NavMenuOption[] => {
    return [
      generalMenuItems[GeneralPages.SHOP_CARS],
      generalMenuItems[GeneralPages.SELL_OR_TRADE],
      generalMenuItems[GeneralPages.FINANCING],
      generalMenuItems[GeneralPages.MORE],
    ];
  };

  const getDesktopUserItems = (): NavMenuOption[] => {
    return Object.values(userMenuItems);
  };

  const getMobileMenuItems = (): NavMenuOption[] => {
    if (authContext.isAuthenticated) {
      return [
        generalMenuItems[GeneralPages.SHOP_CARS],
        generalMenuItems[GeneralPages.SELL_OR_TRADE],
        userMenuItems[UserPages.MY_ORDERS],
        userMenuItems[UserPages.MY_CARS],
        generalMenuItems[GeneralPages.FINANCING],
        generalMenuItems[GeneralPages.MORE],
      ];
    }
    return [
      generalMenuItems[GeneralPages.SHOP_CARS],
      generalMenuItems[GeneralPages.SELL_OR_TRADE],
      generalMenuItems[GeneralPages.FINANCING],
      generalMenuItems[GeneralPages.MORE],
    ];
  };

  return { getDesktopMenuItems, getDesktopUserItems, getMobileMenuItems, openLoginModal };
};
