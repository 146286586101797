import { provinceMap } from '@clutch/clutch-common/lib/constants';
import { formatPhoneNumber, numberMask, hasValue } from '@clutch/helpers';
import { useBooleanState, useFormState, useWindowResize } from '@clutch/hooks';
import { ToolTip } from '@clutch/torque-ui';
import { Stack } from '@mui/material';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { Flex } from 'rebass';

import { AddressForm } from 'src/components/AddressForm';
import { NumberInput, PhoneInput, SelectInput, TextInput } from 'src/components/FormInput';
import { theme } from 'src/theme';

import useKeyPressListener from '../../../../../../../hooks/useKeyPress';
import { formatDuration } from '../../../../Review/utils';
import { ResidenceDurationForm } from '../../../ResidenceDurationForm';
import * as StyledForm from '../../../styles';
import type { EmploymentState } from '../../utils';
import { EmploymentDetailFields, EmploymentStatus, isActiveValid, isMilitaryValid } from '../../utils';
import { employmentDetailsTestPayload } from '../../utils/testPayloads';
import * as Styled from './EmploymentCard.styles';

const EmploymentStatusOptions = [
  { value: 'FULL_TIME', label: 'Full time' },
  { value: 'CONTRACT', label: 'Contract' },
  { value: 'SELF_EMPLOYED', label: 'Self-employed' },
  { value: 'UNEMPLOYED', label: 'Unemployed' },
  { value: 'MILITARY', label: 'Military' },
  { value: 'RETIRED', label: 'Retired' },
];

type EmploymentCardProps = {
  onChange: (employment: EmploymentState) => any;
  employment: EmploymentState;
  onDelete?: () => any;
  onOpen?: () => any;
  isCurrentEmployment?: boolean;
  inModal?: boolean;
};
export const EmploymentCard = ({ onChange, employment, onDelete, onOpen, isCurrentEmployment, inModal }: EmploymentCardProps) => {
  const isAnnualIncomeTooltipOpenState = useBooleanState();
  const { windowWidth } = useWindowResize();

  const shouldShortenIncomeLabel = windowWidth > theme.breakpointValues.xs && windowWidth <= 600;

  const isComplete = employment.isComplete;
  const year = employment.data.monthsAtEmployer ? Math.floor(employment.data.monthsAtEmployer / 12) : undefined;
  const month = employment.data.monthsAtEmployer ? employment.data.monthsAtEmployer % 12 : undefined;
  const branch = employment.data.type === EmploymentStatus.MILITARY ? employment.data.militaryBranch : null;
  const rank = employment.data.type === EmploymentStatus.MILITARY ? employment.data.militaryRank : null;

  const { jobTitle, companyName, apartment, city, provinceCode, street, postalCode, phoneNumber, country }: Record<string, any> =
    employment.data.type === EmploymentStatus.FULL_TIME ||
    employment.data.type === EmploymentStatus.CONTRACT ||
    employment.data.type === EmploymentStatus.SELF_EMPLOYED
      ? {
          companyName: employment.data?.companyName,
          city: employment.data?.city,
          provinceCode: employment.data?.provinceCode,
          street: employment.data?.street,
          postalCode: employment.data?.postalCode,
          phoneNumber: employment.data?.phoneNumber,
          country: employment.data?.country,
          apartment: employment.data?.apartment,
          jobTitle: employment.data?.jobTitle,
        }
      : {};

  const [isAddressValid, setIsAddressValid] = useState(isComplete);

  const provinceName = provinceMap[provinceCode]?.name;
  const formState = useFormState({
    formKeyMap: EmploymentDetailFields,
    optionalKeys: [
      EmploymentDetailFields.BRANCH,
      EmploymentDetailFields.RANK,
      EmploymentDetailFields.ADDRESS,
      EmploymentDetailFields.NAME,
      EmploymentDetailFields.PHONE_NUMBER,
      EmploymentDetailFields.TITLE,
      EmploymentDetailFields.LENGTH_MONTH,
      EmploymentDetailFields.LENGTH_YEAR,
    ],
    defaultValues: {
      ...employment.data,
      [EmploymentDetailFields.LENGTH_MONTH]: month,
      [EmploymentDetailFields.LENGTH_YEAR]: year,
      [EmploymentDetailFields.BRANCH]: branch,
      [EmploymentDetailFields.RANK]: rank,
      [EmploymentDetailFields.TITLE]: jobTitle,
      [EmploymentDetailFields.NAME]: companyName,
      [EmploymentDetailFields.PHONE_NUMBER]: phoneNumber,
      [EmploymentDetailFields.ADDRESS]: {
        country: country,
        street: street,
        apartment: apartment,
        city: city,
        province: provinceName,
        provinceCode: provinceCode,
        postalCode: postalCode,
      },
    },
  });
  const isActive =
    formState.getValueForKey(EmploymentDetailFields.STATUS) === EmploymentStatus.FULL_TIME ||
    formState.getValueForKey(EmploymentDetailFields.STATUS) === EmploymentStatus.SELF_EMPLOYED ||
    formState.getValueForKey(EmploymentDetailFields.STATUS) === EmploymentStatus.CONTRACT;
  const isMilitary = formState.getValueForKey(EmploymentDetailFields.STATUS) === EmploymentStatus.MILITARY;
  const isUnemployed = formState.getValueForKey(EmploymentDetailFields.STATUS) === EmploymentStatus.UNEMPLOYED;

  const employmentType = () => {
    EmploymentStatusOptions.find(option => {
      return option.value === employment.data.type;
    });
    return EmploymentStatusOptions.find(option => option.value === employment.data.type)?.label;
  };

  const defaultIncomeLabel = shouldShortenIncomeLabel ? 'Annual Income' : 'Gross Annual Income';

  const annualIncomeLabel =
    (formState.getValueForKey(EmploymentDetailFields.STATUS) === EmploymentStatus.RETIRED && 'Annual retirement income') ||
    (formState.getValueForKey(EmploymentDetailFields.STATUS) === EmploymentStatus.UNEMPLOYED &&
      'Annual Employment Insurance (If applicable)') ||
    defaultIncomeLabel;

  const employmentData = formState.getPayload();
  const monthsActive =
    (employmentData.yearsWith ? employmentData.yearsWith * 12 : 0) + (employmentData.monthsWith ? employmentData.monthsWith : 0);

  const isAnnualIncomeValid =
    formState.getValueForKey(EmploymentDetailFields.STATUS) === EmploymentStatus.UNEMPLOYED ||
    formState.getValueForKey(EmploymentDetailFields.ANNUAL_INCOME) > 0;

  const isPhoneValid = !isActive || /^(?!(\d)\1{9})\d{10}$/.test(formState.getValueForKey(EmploymentDetailFields.PHONE_NUMBER));

  const isValid =
    formState.isFormValid() &&
    isAnnualIncomeValid &&
    monthsActive > 0 &&
    isPhoneValid &&
    (!isMilitary || isMilitaryValid({ formState, formKeyMap: EmploymentDetailFields })) &&
    (!isActive || isActiveValid({ formState, formKeyMap: EmploymentDetailFields })) &&
    (isActive ? isAddressValid : true);

  useKeyPressListener({
    keys: ['Alt', 'ArrowLeft', 'ArrowRight'],
    onTrigger: () => {
      Object.keys(employmentDetailsTestPayload).forEach(key => {
        formState.handleValueChange(key, employmentDetailsTestPayload[key as keyof typeof employmentDetailsTestPayload]);
      });
    },
  });

  useEffect(() => {
    if (isUnemployed && formState.getValueForKey(EmploymentDetailFields.ANNUAL_INCOME) === undefined) {
      formState.handleValueChange(EmploymentDetailFields.ANNUAL_INCOME, 0);
    }
    if (!isUnemployed && formState.getValueForKey(EmploymentDetailFields.ANNUAL_INCOME) === 0) {
      formState.handleValueChange(EmploymentDetailFields.ANNUAL_INCOME, undefined);
    }

    if (isMilitary || isUnemployed) {
      formState.handleValueChange(EmploymentDetailFields.ADDRESS, null);
      formState.handleValueChange(EmploymentDetailFields.NAME, null);
      formState.handleValueChange(EmploymentDetailFields.PHONE_NUMBER, null);
      formState.handleValueChange(EmploymentDetailFields.TITLE, null);
    }
  }, [formState.getValueForKey(EmploymentDetailFields.STATUS)]);

  useEffect(() => {
    const onChangeData = {
      current: isCurrentEmployment,
      monthsAtEmployer: monthsActive,
      ...(isMilitary && {
        militaryBranch: employmentData.militaryBranch,
        militaryRank: employmentData.militaryRank,
      }),
      ...(isActive && {
        companyName: employmentData.companyName,
        phoneNumber: employmentData.phoneNumber,
        apartment: employmentData.address?.apartment,
        city: employmentData.address?.city,
        provinceCode: employmentData.address?.provinceCode,
        street: employmentData.address?.street,
        postalCode: employmentData.address?.postalCode,
        country: employmentData.address?.country,
      }),
      type: employmentData.type,
      grossIncome: employmentData.grossIncome,
      jobTitle: employmentData.jobTitle,
    };

    onChange({
      isComplete,
      isValid,
      employmentId: employment.employmentId,
      data: onChangeData,
    });
  }, [JSON.stringify(employmentData), isValid]);

  return (
    <StyledForm.CardContainer isComplete={isComplete}>
      <Flex flexDirection="column">
        <Flex flexDirection="row" justifyContent="space-between">
          <StyledForm.Header inModal={inModal}>{`${isCurrentEmployment ? 'Current' : 'Previous'} employment`}</StyledForm.Header>
          {!(isCurrentEmployment && !isComplete) && (
            <StyledForm.Pill
              label={isComplete ? 'Edit' : 'Remove'}
              variant="outlined"
              icon={isComplete ? <StyledForm.EditIcon /> : <StyledForm.DeleteIcon />}
              onClick={isComplete ? onOpen : onDelete}
            />
          )}
        </Flex>

        {isComplete && inModal && (
          <StyledForm.AddressLine>
            {employmentType()} - {formatDuration(monthsActive)}
          </StyledForm.AddressLine>
        )}

        {isComplete && !inModal && (
          <>
            <StyledForm.AddressLine>{employmentType()}</StyledForm.AddressLine>
            <Styled.DetailsWrapper>
              {employment.data?.companyName && (
                <StyledForm.InfoContainer>
                  <StyledForm.SubHeader>Employer</StyledForm.SubHeader>
                  <StyledForm.AddressLine>{employment.data.companyName}</StyledForm.AddressLine>
                </StyledForm.InfoContainer>
              )}
              {employment.data.phoneNumber && (
                <StyledForm.InfoContainer>
                  <StyledForm.SubHeader>Contact</StyledForm.SubHeader>
                  <StyledForm.AddressLine>{formatPhoneNumber(employment.data.phoneNumber)}</StyledForm.AddressLine>
                </StyledForm.InfoContainer>
              )}
              {isActive && (
                <StyledForm.InfoContainer>
                  <StyledForm.SubHeader>Address</StyledForm.SubHeader>
                  <Styled.AddressContainer>
                    {employment.data.apartment && (
                      <StyledForm.AddressLine mobileWidth="100%">{employment.data.apartment}</StyledForm.AddressLine>
                    )}
                    {employment.data.street && <StyledForm.AddressLine mobileWidth="100%">{employment.data.street}</StyledForm.AddressLine>}
                    <StyledForm.AddressLine mobileWidth="100%">
                      {`${employment.data.city ? employment.data.city + ',' : ''} 
                      ${employment.data.province ? employment.data.province + ',' : ''}
                      ${employment.data.postalCode ? employment.data.postalCode.replace(' ', '') : ''} `}
                    </StyledForm.AddressLine>
                    {employment.data.country && (
                      <StyledForm.AddressLine mobileWidth="100%">{employment.data.country}</StyledForm.AddressLine>
                    )}
                  </Styled.AddressContainer>
                </StyledForm.InfoContainer>
              )}
              {employment.data.militaryBranch && (
                <StyledForm.InfoContainer>
                  <StyledForm.SubHeader>Branch</StyledForm.SubHeader>
                  <StyledForm.AddressLine>{employment.data.militaryBranch}</StyledForm.AddressLine>
                </StyledForm.InfoContainer>
              )}
              {employment.data.militaryRank && (
                <StyledForm.InfoContainer>
                  <StyledForm.SubHeader>Rank</StyledForm.SubHeader>
                  <StyledForm.AddressLine>{employment.data.militaryRank}</StyledForm.AddressLine>
                </StyledForm.InfoContainer>
              )}

              {employment.data.jobTitle && (
                <StyledForm.InfoContainer>
                  <StyledForm.SubHeader>Job title</StyledForm.SubHeader>
                  <StyledForm.AddressLine>{employment.data.jobTitle}</StyledForm.AddressLine>
                </StyledForm.InfoContainer>
              )}
              {employment.data.grossIncome !== undefined && (
                <StyledForm.InfoContainer>
                  <StyledForm.SubHeader>Annual income</StyledForm.SubHeader>
                  <StyledForm.AddressLine>{`$${numberMask(employment.data.grossIncome)}`}</StyledForm.AddressLine>
                </StyledForm.InfoContainer>
              )}
              <StyledForm.InfoContainer>
                <StyledForm.SubHeader>Duration</StyledForm.SubHeader>
                <StyledForm.AddressLine>{formatDuration(monthsActive)}</StyledForm.AddressLine>
              </StyledForm.InfoContainer>
            </Styled.DetailsWrapper>
          </>
        )}

        {!isComplete && (
          <>
            <SelectInput
              label="Employment status"
              value={formState.getValueForKey(EmploymentDetailFields.STATUS)}
              onChange={(event: any) => {
                formState.handleValueChange(EmploymentDetailFields.STATUS, event.target.value);
              }}
              options={EmploymentStatusOptions}
              placeholder="Select one"
            />

            {employment.data.type !== undefined && (
              <>
                {isActive && (
                  <>
                    <TextInput
                      label="Employer"
                      value={formState.getValueForKey(EmploymentDetailFields.NAME)}
                      onChange={(event: any) => {
                        formState.handleValueChange(EmploymentDetailFields.NAME, event.target.value);
                      }}
                      errorMessage={'Employer is required'}
                      error={
                        !R.isNil(formState.getValueForKey(EmploymentDetailFields.NAME)) &&
                        !hasValue(formState.getValueForKey(EmploymentDetailFields.NAME).trim())
                      }
                      disableErrorOnFocus
                    />

                    <PhoneInput
                      label="Employer phone number"
                      value={formState.getValueForKey(EmploymentDetailFields.PHONE_NUMBER)}
                      onChange={(event: any) => {
                        formState.handleValueChange(EmploymentDetailFields.PHONE_NUMBER, event.target.value);
                      }}
                      errorMessage="Please enter a valid phone number."
                      disableErrorOnFocus
                    />

                    <Stack marginBottom={{ base: 2, xs: 3 }}>
                      <AddressForm
                        setAddress={formState.handleValueChange(EmploymentDetailFields.ADDRESS)}
                        defaultAddress={formState.getValueForKey(EmploymentDetailFields.ADDRESS)}
                        setIsFormValid={setIsAddressValid}
                      />
                    </Stack>
                  </>
                )}

                {isMilitary && (
                  <Flex justifyContent={'space-between'} flexDirection={'row'} width={1}>
                    <Flex marginRight={'10px'} width={1}>
                      <TextInput
                        label="Rank"
                        value={formState.getValueForKey(EmploymentDetailFields.RANK)}
                        onChange={(event: any) => {
                          formState.handleValueChange(EmploymentDetailFields.RANK, event.target.value);
                        }}
                        errorMessage={'Rank is required'}
                        error={
                          formState.getValueForKey(EmploymentDetailFields.RANK) !== null &&
                          !hasValue(formState.getValueForKey(EmploymentDetailFields.RANK).trim())
                        }
                        disableErrorOnFocus
                      />
                    </Flex>
                    <Flex marginLeft="10px" width={1}>
                      <TextInput
                        label="Branch"
                        value={formState.getValueForKey(EmploymentDetailFields.BRANCH)}
                        onChange={(event: any) => {
                          formState.handleValueChange(EmploymentDetailFields.BRANCH, event.target.value);
                        }}
                        errorMessage={'Branch is required'}
                        error={
                          formState.getValueForKey(EmploymentDetailFields.BRANCH) !== null &&
                          !hasValue(formState.getValueForKey(EmploymentDetailFields.BRANCH).trim())
                        }
                        disableErrorOnFocus
                      />
                    </Flex>
                  </Flex>
                )}

                <StyledForm.RowWrapper>
                  {isActive && (
                    <StyledForm.RowItem>
                      <TextInput
                        label="Job title"
                        value={formState.getValueForKey(EmploymentDetailFields.TITLE)}
                        onChange={(event: any) => {
                          formState.handleValueChange(EmploymentDetailFields.TITLE, event.target.value);
                        }}
                        errorMessage={'Job title is required'}
                        error={
                          !R.isNil(formState.getValueForKey(EmploymentDetailFields.TITLE)) &&
                          !hasValue(formState.getValueForKey(EmploymentDetailFields.TITLE).trim())
                        }
                        disableErrorOnFocus
                      />
                    </StyledForm.RowItem>
                  )}
                  <StyledForm.RowItem width={!isActive ? '100%' : '48%'} flexDirection="column">
                    <Flex>
                      <StyledForm.Label>{annualIncomeLabel}</StyledForm.Label>
                      {annualIncomeLabel === defaultIncomeLabel && (
                        <ToolTip
                          version="2"
                          title="Your gross annual income is your total annual income including bonuses, before tax and deductions"
                          disableHoverListener
                          open={isAnnualIncomeTooltipOpenState.value}
                          onMouseEnter={isAnnualIncomeTooltipOpenState.setTrue}
                          onMouseLeave={isAnnualIncomeTooltipOpenState.setFalse}
                        />
                      )}
                    </Flex>
                    <NumberInput
                      value={formState.getValueForKey(EmploymentDetailFields.ANNUAL_INCOME)}
                      onChange={(event: any) => formState.handleValueChange(EmploymentDetailFields.ANNUAL_INCOME, event.target.value)}
                      addDollarSign
                      errorMessage="An annual income is required"
                      error={!isAnnualIncomeValid && formState.getValueForKey(EmploymentDetailFields.ANNUAL_INCOME) !== undefined}
                      allowZero={isUnemployed}
                    />
                  </StyledForm.RowItem>
                </StyledForm.RowWrapper>

                <ResidenceDurationForm
                  formState={formState}
                  yearKey={EmploymentDetailFields.LENGTH_YEAR}
                  monthKey={EmploymentDetailFields.LENGTH_MONTH}
                  label="Duration"
                />
              </>
            )}
          </>
        )}
      </Flex>
    </StyledForm.CardContainer>
  );
};
