import dayjs from 'dayjs';
import type { PickupLocation } from 'src/contexts/retailCheckout/RetailCheckoutContext.types';

export const getEarliestPickupDate = ({ pickupLocations }: { pickupLocations: PickupLocation[] }) => {
  if (!pickupLocations || pickupLocations.length === 0) {
    return '';
  }

  // Find the earliest pickup date for an order,
  // from the list of available pickup locations
  const earliestPickUpDate = pickupLocations.reduce((earliest: PickupLocation, current: PickupLocation) => {
    return dayjs(earliest.getAsSoonAs).isBefore(dayjs(current.getAsSoonAs)) ? earliest : current;
  });

  const formattedDate = dayjs(earliestPickUpDate.getAsSoonAs).format('MMMM D');

  return formattedDate;
};
