import { useEffect, useState } from 'react';

import type { PrivatePurchaseOffer } from 'src/api/privatePurchase';

export enum OfferType {
  SELL = 'SELL',
  WHOLESALE = 'WHOLESALE',
  LEASE = 'LEASE',
  LIEN = 'LIEN',
  NEGATIVE_EQUITY_LEASE = 'NEGATIVE_EQUITY_LEASE',
  NEGATIVE_EQUITY_LIEN = 'NEGATIVE_EQUITY_LIEN',
}

export type BreakdownData = {
  name: BreakdownEntry;
  label?: string;
  value?: number | null;
  isNegative?: boolean;
};

export enum BreakdownEntry {
  MARKET_VALUE = "Your car's market value",
  SERVICE_FEE = 'Clutch service fee (5%)',
  REFERRAL_REWARD = 'Referral reward',
  REMAINING_AMOUNT_ON_LEASE = 'Remaining balance on lease',
  BUYOUT_FEE = 'Lease buyout fee',
  REMAINING_AMOUNT_ON_LIEN = 'Remaining balance on lien',
  TAX_SAVINGS = 'Tax savings',
  DROP_OFF_TOMORROW = 'Drop-off bonus',
}

export type Breakdown = {
  [K in OfferType]: BreakdownEntry[];
};

const commonBreakdown: BreakdownEntry[] = [
  BreakdownEntry.MARKET_VALUE,
  BreakdownEntry.SERVICE_FEE,
  BreakdownEntry.REFERRAL_REWARD,
  BreakdownEntry.DROP_OFF_TOMORROW,
];

const DefaultBreakdown: Breakdown = {
  [OfferType.SELL]: [...commonBreakdown, BreakdownEntry.TAX_SAVINGS],
  [OfferType.WHOLESALE]: commonBreakdown,
  [OfferType.LEASE]: [...commonBreakdown, BreakdownEntry.REMAINING_AMOUNT_ON_LEASE, BreakdownEntry.BUYOUT_FEE, BreakdownEntry.TAX_SAVINGS],
  [OfferType.LIEN]: [...commonBreakdown, BreakdownEntry.REMAINING_AMOUNT_ON_LIEN, BreakdownEntry.TAX_SAVINGS],
  [OfferType.NEGATIVE_EQUITY_LEASE]: [...commonBreakdown, BreakdownEntry.REMAINING_AMOUNT_ON_LEASE, BreakdownEntry.TAX_SAVINGS],
  [OfferType.NEGATIVE_EQUITY_LIEN]: [...commonBreakdown, BreakdownEntry.REMAINING_AMOUNT_ON_LIEN, BreakdownEntry.TAX_SAVINGS],
};

type BreakdownEntryToDataMapType = {
  [K in BreakdownEntry]: (value: number | null | undefined) => BreakdownData;
};
const BreakdownEntryToDataMap: BreakdownEntryToDataMapType = {
  [BreakdownEntry.MARKET_VALUE]: value => ({ name: BreakdownEntry.MARKET_VALUE, value }),
  [BreakdownEntry.SERVICE_FEE]: value => ({ name: BreakdownEntry.SERVICE_FEE, value, isNegative: true }),
  [BreakdownEntry.REFERRAL_REWARD]: value => ({ name: BreakdownEntry.REFERRAL_REWARD, value }),
  [BreakdownEntry.REMAINING_AMOUNT_ON_LEASE]: value => ({
    name: BreakdownEntry.REMAINING_AMOUNT_ON_LEASE,
    value,
    isNegative: true,
  }),
  [BreakdownEntry.BUYOUT_FEE]: value => ({ name: BreakdownEntry.BUYOUT_FEE, value, isNegative: true }),
  [BreakdownEntry.REMAINING_AMOUNT_ON_LIEN]: value => ({
    name: BreakdownEntry.REMAINING_AMOUNT_ON_LIEN,
    value,
    isNegative: true,
  }),
  [BreakdownEntry.TAX_SAVINGS]: value => ({ name: BreakdownEntry.TAX_SAVINGS, value }),
  [BreakdownEntry.DROP_OFF_TOMORROW]: value => ({ name: BreakdownEntry.DROP_OFF_TOMORROW, value }),
};

type PrivatePurchaseOfferBreakdownProviderProps = {
  offer: PrivatePurchaseOffer | null;
  leaseBuyoutFee?: number | null;
  taxSavings?: number | null;
};
export type PrivatePurchaseOfferBreakdownProviderReturn = {
  isTradeIn: boolean;
  breakdown: BreakdownData[];
  marketValue: number;
  inflatedMarketValue: number;
  serviceFee: number;
  getFinalPayout: () => number;
  offerType: OfferType;
  applicableIncentive?: { incentiveAmount: number };
};
const usePrivatePurchaseOfferBreakdown = ({
  offer,
  leaseBuyoutFee,
  taxSavings,
}: PrivatePurchaseOfferBreakdownProviderProps): PrivatePurchaseOfferBreakdownProviderReturn => {
  // constants
  const totalLienAmount = offer?.totalLienAmount || 0;
  const leaseMonthlyPayment = offer?.leaseMonthlyPayment || 0;
  const leaseMonthsRemaining = offer?.leaseMonthsRemaining || 0;
  const leasePurchaseOption = offer?.leasePurchaseOption || 0;
  const priceDetails = offer?.priceDetails;

  // seperate from offerType
  // offerType.T could be either trade in or not
  const isTradeIn = offer?.type === 'TRADE';

  const isLease = Boolean(totalLienAmount && leaseMonthlyPayment && leaseMonthsRemaining && leasePurchaseOption);
  const isLien = Boolean(totalLienAmount) && !isLease;
  const isNegativeEquityLease = priceDetails?.equityStatus === 'NEGATIVE' && isLease;
  const isNegativeEquityLien = priceDetails?.equityStatus === 'NEGATIVE' && isLien;

  // Adding tax savings to the inflated value
  const marketValue = (offer?.priceDetails?.vehicleValue || 0) + (taxSavings || 0);
  const applicableIncentive = offer?.applicableIncentive;
  const leaseBuyoutFeeDollars = leaseBuyoutFee === undefined ? undefined : (leaseBuyoutFee || 0) / 100;
  const dropOffIncentive = offer?.STCDropOffIncentive;

  // propogation fuctions for defaults
  const getOfferType = () => {
    if (isNegativeEquityLease) return OfferType.NEGATIVE_EQUITY_LEASE;
    if (isNegativeEquityLien) return OfferType.NEGATIVE_EQUITY_LIEN;
    if (isLease) return OfferType.LEASE;
    if (isLien) return OfferType.LIEN;
    // default to sell
    return OfferType.SELL;
  };
  const offerType = getOfferType();

  // states
  const [breakdown, setBreakdown] = useState<BreakdownData[]>([]);
  const [inflatedMarketValue, setInflatedMarketValue] = useState<number>(0);
  const [serviceFee, setServiceFee] = useState<number>(0);

  // helper functions
  const getFinalPayout = () => {
    return breakdown.reduce((acc, _breakdown) => {
      return acc + (_breakdown.isNegative ? -1 : 1) * Math.abs(_breakdown.value ?? 0);
    }, 0);
  };
  const getTotalLeaseAmount = () => {
    if (offer === null) return undefined;

    // offer == null destructuring safe guard
    const { leaseMonthlyPayment, leaseMonthsRemaining, leasePurchaseOption, totalLienAmount } = offer || {};
    if (isLease || isNegativeEquityLease) {
      // we can assume that these values are defined because the offerType is LEASE
      return leaseMonthlyPayment! * leaseMonthsRemaining! + leasePurchaseOption!;
    }

    if (isLien || isNegativeEquityLien) {
      // we can assume that these values are defined because the offerType is NEGATIVE_EQUITY
      return totalLienAmount!;
    }

    // return undefined if the offerType is not LEASE
    return undefined;
  };
  const getReferralReward = () => {
    // we do not add referral reward if it is a trade in
    if (isTradeIn) return undefined;
    return offer?.applicableIncentive?.incentiveAmount;
  };

  // state setters wrapper
  const SetStates = () => {
    const totalPrice = offer?.priceDetails?.totalPrice || 0;

    const tradeInTaxSavings = taxSavings || 0;
    // Tax savings only for trade in
    const stcTaxSavings = isTradeIn ? tradeInTaxSavings : 0;

    const recalculateMarketValue = isTradeIn && totalPrice;

    // set inflated market value including tax savings
    const offerMarketValue = recalculateMarketValue ? (totalPrice + stcTaxSavings) / 0.95 : offer?.priceDetails?.marketValue || 0;
    setInflatedMarketValue(offerMarketValue);

    // set service fee
    const serviceFee = recalculateMarketValue
      ? Math.abs(offerMarketValue - (totalPrice + stcTaxSavings))
      : offer?.priceDetails?.serviceFee || 0;
    setServiceFee(serviceFee);

    // inflated market value without tax savings
    const marketValueWithoutTaxSavings = offerMarketValue - stcTaxSavings;

    // set breakdown
    const breakdownEntries = DefaultBreakdown[offerType];

    const breakdown = breakdownEntries.map(entry => {
      const breakdownEntryToDataMap = BreakdownEntryToDataMap[entry];

      // get the value depending on the entry
      const getValue = () => {
        switch (entry) {
          // Use vehicle value as market value includes tax savings which is a separate item
          case BreakdownEntry.MARKET_VALUE:
            return marketValueWithoutTaxSavings;
          case BreakdownEntry.TAX_SAVINGS:
            return stcTaxSavings;
          case BreakdownEntry.SERVICE_FEE:
            return serviceFee;
          case BreakdownEntry.REFERRAL_REWARD:
            return getReferralReward();
          case BreakdownEntry.REMAINING_AMOUNT_ON_LEASE:
            return getTotalLeaseAmount();
          case BreakdownEntry.REMAINING_AMOUNT_ON_LIEN:
            return getTotalLeaseAmount();
          case BreakdownEntry.BUYOUT_FEE:
            return leaseBuyoutFeeDollars;
          case BreakdownEntry.DROP_OFF_TOMORROW:
            //If dropOffIncentive is zero, need to send undefined/null instead of 0
            return dropOffIncentive || null;
          default:
            return 0;
        }
      };
      const val = getValue();
      return breakdownEntryToDataMap(val);
    });
    setBreakdown(breakdown);
  };

  // sets the initial states
  useEffect(() => {
    if (offer) {
      SetStates();
    }
  }, [offer, taxSavings]);

  return {
    isTradeIn,
    breakdown,
    marketValue,
    inflatedMarketValue,
    serviceFee,
    getFinalPayout,
    offerType,
    applicableIncentive,
  };
};

export default usePrivatePurchaseOfferBreakdown;
