import { Stack, Typography } from '@mui/material';
import { theme } from 'src/theme';
import * as R from 'ramda';
import { styled } from '@mui/material/styles';
import { formatMileage } from '@clutch/helpers';

const TrimSection = styled(Stack)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const CarDetails = ({ vehicle }: { vehicle: any }) => {
  const make = R.pathOr('', ['make', 'name'], vehicle);
  const model = R.pathOr('', ['model', 'name'], vehicle);
  const trimSection = () => {
    const trim = R.pathOr('', ['trim', 'name'], vehicle);
    const extraText = R.pathOr('', ['extraText'], vehicle);

    const trimText = `${trim.length > 0 ? `${trim} ` : ''}${extraText}`;
    if (trimText.length > 0) {
      return (
        <TrimSection flex="0 1 auto" maxWidth={1} direction="row" spacing={1} alignItems="center">
          <Typography variant="body2" color={theme.colors.blackTitle} textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
            {trimText}
          </Typography>
          <Typography fontSize="8px" variant="body2">
            •
          </Typography>
        </TrimSection>
      );
    }
    return <></>;
  };

  return (
    <Stack spacing={1} width={1} direction="column">
      <Typography
        color={theme.colors.blackTitle}
        fontSize="16px"
        fontWeight={500}
        lineHeight="20px"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
      >{`${vehicle.year} ${make} ${model}`}</Typography>
      <Stack alignItems="center" width={1} direction="row" spacing={1}>
        {trimSection()}
        <TrimSection flex="1 0 auto">
          <Typography variant="body2">{!!vehicle.mileage && `${formatMileage(Number(vehicle.mileage))} km`}</Typography>
        </TrimSection>
      </Stack>
    </Stack>
  );
};
