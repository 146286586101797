import { CITY_DETAILS } from '@clutch/common/constants/citySTC.data';
import type { CityData } from '@clutch/common/types/citySTC.types';
import { normalizeCityName } from '@clutch/common/utils/normalize-city-name';
import { matchPath } from 'react-router-dom';

import { ROUTES } from 'src/static/routes';

export const getCityNames = (filterFooter = true): string[] => {
  return Object.values(CITY_DETAILS)
    .filter(city => !filterFooter || city.highlightInFooter)
    .map(cityData => cityData.name);
};

const getCityKey = (cityName: string): string | undefined => {
  return Object.keys(CITY_DETAILS).find(key => normalizeCityName(key) === normalizeCityName(cityName));
};

export const getCityFromUrl = (pathname: string): string | undefined => {
  const match = matchPath<{ city?: string }>(pathname, ROUTES.SELL_MY_CAR.BASE + ROUTES.SELL_MY_CAR.PATH);

  return match?.params?.city;
};

export const findCityFromPathname = (pathname: string): CityData | undefined => {
  const city = getCityFromUrl(pathname);
  if (city) {
    const cityKey = getCityKey(city);
    if (cityKey) {
      return CITY_DETAILS[cityKey];
    }
  }
  return undefined;
};

export function getCityString(prefix: string, city: string): string {
  return city && city.trim() !== '' ? ` ${prefix} ${city.trim()}` : '';
}

export function getSellMyCarForCityUrl(city: string): string {
  return `${ROUTES.SELL_MY_CAR.BASE}-${normalizeCityName(city)}`;
}
