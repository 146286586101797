import type { ChangeEventHandler } from 'react';

import { TextInput } from '../TextInput';

type EmailInputProps = {
  placeholder?: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  label?: string;
  autoComplete?: string;
  errorMessage?: string;
  error?: boolean;
  disabled?: boolean;
  disableErrorOnFocus?: boolean;
  minHeight?: string;
};

export const EmailInput = ({
  placeholder = '',
  value,
  onChange,
  label,
  autoComplete,
  errorMessage,
  error,
  disabled,
  disableErrorOnFocus = false,
  minHeight,
}: EmailInputProps) => (
  <TextInput
    type="email"
    value={value}
    onChange={onChange}
    label={label}
    placeholder={placeholder}
    autoComplete={autoComplete}
    errorMessage={errorMessage}
    error={error}
    disabled={disabled}
    disableErrorOnFocus={disableErrorOnFocus}
    minHeight={minHeight}
  />
);
