import { Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { Modal } from 'src/components/molecules/Modal';
import { AuthContext } from 'src/contexts';
import { isFinancingAllowed } from 'src/helpers';
import { theme } from 'src/theme';
import * as R from 'ramda';
import { ROUTES } from 'src/static';
import { useHistory } from 'react-router';
import Button from 'src/components/molecules/atoms/Button';

export const FinancingModal = ({
  open,
  vehicle,
  financeCalculator,
  onClose = () => {},
}: {
  open: boolean;
  vehicle: any;
  financeCalculator: any;
  onClose: (e: any) => void;
}) => {
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const make = R.pathOr('', ['make', 'name'], vehicle);
  const model = R.pathOr('', ['model', 'name'], vehicle);

  const { purchaseValueBiWeekly, totalLoanAmount, loanLength, aprRate } = financeCalculator;
  const financingAllowed =
    !!purchaseValueBiWeekly &&
    !!vehicle &&
    isFinancingAllowed({
      isDealer: user?.isDealer,
      vehicle,
      totalLoanAmount,
    });

  return (
    <Modal
      onClick={e => {
        e.preventDefault();
      }}
      bodyPadding="0"
      open={financingAllowed && !!vehicle && open}
      onClose={onClose}
      title="Financing"
    >
      <Stack width={{ base: 1, md: '440px' }}>
        <Stack spacing={2} p="24px">
          <Typography variant="subtitle2">
            {vehicle?.year} {make} {model}
          </Typography>
          <Typography variant="h3">${purchaseValueBiWeekly} / biweekly</Typography>
          <Typography variant="body1">
            Biweekly payment is an estimate based on a {loanLength}-month term with an APR of {aprRate}%
          </Typography>
        </Stack>
        <Stack p="24px" bgcolor={theme.colors.offWhite} borderTop={`1px solid ${theme.colors.whisperGrey}`} spacing={2}>
          <Typography variant="body1">
            Personalize your financing terms in minutes, with <span className="bold">no impact to your credit score</span>
          </Typography>
          <Button
            size="small"
            onClick={e => {
              e.preventDefault();
              history.push(ROUTES.PRE_QUALIFICATION[0]);
            }}
            fullWidth
            color="secondary"
          >
            <Typography p="8px 32px" fontSize={{ base: '16px' }} fontWeight="700" color={theme.colors.white} lineHeight="24px">
              Get pre-qualified for a loan
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
