import type { Address } from 'src/components/AddressForm/types/address';
import type { RetailCheckoutSteps } from 'src/contexts/retailCheckout/utils';

import ClutchApiWrapper from './client/clutchAPIWrapper';
import type { PrivatePurchaseOffer } from './privatePurchase';

type UpdateCheckoutOpts = {
  checkoutId: string;
  payload: Record<string, any>;
  stepKey: RetailCheckoutSteps;
  queryParams?: Record<string, boolean | number | string>;
};

type TradeInOptions = {
  checkoutId: string;
  payload: PrivatePurchaseOffer;
};

type ReceptionTypesOptions = {
  checkoutId: string;
};

type PaymentTypesOptions = {
  checkoutId: string;
};

type UpdateDeliveryAddressOptions = {
  checkoutId: string;
  deliveryAddress: Address;
  confirmed?: boolean;
};

const updateCheckout = (options: UpdateCheckoutOpts) =>
  ClutchApiWrapper.api.authenticated.patch(`/retail/checkouts/${options.checkoutId}/${options.stepKey}`, options.payload, {
    params: options?.queryParams,
  });

const getTradeInOffer = (options: TradeInOptions) =>
  ClutchApiWrapper.api.authenticated.post(`/retail/checkouts/${options.checkoutId}/privatePurchase/getOffer`, options.payload);

const getReceptionTypes = (options: ReceptionTypesOptions) =>
  ClutchApiWrapper.api.authenticated.get(`/retail/checkouts/${options.checkoutId}/reception/types`);

const getPaymentTypes = (options: PaymentTypesOptions) =>
  ClutchApiWrapper.api.authenticated.get(`/retail/checkouts/${options.checkoutId}/payment/types`);

const updateDeliveryAddress = (options: UpdateDeliveryAddressOptions) =>
  ClutchApiWrapper.api.authenticated.patch(`/retail/checkouts/${options.checkoutId}/reception/address`, options.deliveryAddress, {
    params: { confirmed: options.confirmed },
  });

export default {
  updateCheckout,
  updateDeliveryAddress,
  getPaymentTypes,
  getReceptionTypes,
  getTradeInOffer,
};
