/*
  This is a global enum which all modals should be specified in.
  
  NOTE: Please be strict with your modal naming. Each section of the site should have
  an appropriate prefix. The pattern should be as follows...

  [SITE SECTION SPECIFIC PREFIX]_[MODAL SPECIFIC NAME]
*/

export enum Modals {
  FINANCE_APPLICATION_EDIT_EMPLOYMENT_DETAILS = 'FINANCE_APPLICATION_EDIT_EMPLOYMENT_DETAILS',
  FINANCE_APPLICATION_EDIT_HOUSING_DETAILS = 'FINANCE_APPLICATION_EDIT_HOUSING_DETAILS',
  FINANCE_APPLICATION_EDIT_LOAN_TERMS = 'FINANCE_APPLICATION_EDIT_LOAN_TERMS',
  FINANCE_APPLICATION_EDIT_PERSONAL_DETAILS = 'FINANCE_APPLICATION_EDIT_PERSONAL_DETAILS',
  FINANCE_APPLICATION_INVALID_PATH = 'FINANCE_APPLICATION_INVALID_PATH',
  FINANCE_APPLICATION_REMOVE_ADDITIONAL_INCOME = 'FINANCE_APPLICATION_REMOVE_ADDITIONAL_INCOME',
  FINANCE_APPLICATION_REMOVE_ADDRESS = 'FINANCE_APPLICATION_REMOVE_ADDRESS',

  RETAIL_CHECKOUT_EDIT_ADDRESS = 'RETAIL_CHECKOUT_EDIT_ADDRESS',
  RETAIL_CHECKOUT_EDIT_BUYER_INFO = 'RETAIL_CHECKOUT_EDIT_BUYER_INFO',
  RETAIL_CHECKOUT_PROVINCE_CHANGE_CONFIRMATION = 'RETAIL_CHECKOUT_PROVINCE_CHANGE_CONFIRMATION',
  RETAIL_CHECKOUT_EDIT_DELIVERY_ADDRESS = 'RETAIL_CHECKOUT_EDIT_DELIVERY_ADDRESS',
  RETAIL_CHECKOUT_RESELECT_PACKAGE = 'RETAIL_CHECKOUT_RESELECT_PACKAGE',

  CONTACT_US = 'CONTACT_US',
}
