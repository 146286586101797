import { formatPhoneNumber } from '@clutch/helpers';
import type { ChangeEventHandler } from 'react';

import { stripPhoneNumber } from 'src/helpers';

import { TextInput } from '../TextInput';

type PhoneInputProps = {
  placeholder?: string;
  value: string | undefined;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  label?: string;
  autoComplete?: string;
  errorMessage?: string;
  disabled?: boolean;
  disableErrorOnFocus?: boolean;
  minHeight?: string;
};

export const PhoneInput = ({
  placeholder = '',
  value,
  onChange,
  label,
  autoComplete,
  errorMessage,
  disabled,
  disableErrorOnFocus = false,
  minHeight,
}: PhoneInputProps) => {
  const maskValue = (inputValue: string) => {
    const newValue = formatPhoneNumber(inputValue);
    return newValue;
  };

  const unMaskValue = (inputValue: string) => {
    const newValue = stripPhoneNumber(inputValue);
    return newValue;
  };

  const error = value !== undefined && !/^(?!(\d)\1{9})\d{10}$/.test(value);

  return (
    <TextInput
      value={value ? maskValue(value) : undefined}
      onChange={(event: any) => {
        const newEvent = {
          ...event,
          target: {
            ...event.target,
            value: unMaskValue(event.target.value),
          },
        };
        onChange(newEvent);
      }}
      label={label}
      placeholder={placeholder}
      autoComplete={autoComplete}
      errorMessage={errorMessage}
      error={error}
      disabled={disabled}
      maxLength={14}
      minHeight={minHeight}
      disableErrorOnFocus={disableErrorOnFocus}
    />
  );
};
