import { Container, styled as muiStyled } from '@mui/material';
import theme from 'src/theme/theme';

export const SubSectionDivider = muiStyled(Container)({
  height: '1px',
  width: '100%',
  backgroundColor: theme.colors.moonMistGrey,
  maxWidth: '1076px',
  margin: '0',
});
