import 'lazysizes';
import 'polyfill-object.fromentries';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './antd.css';

import axe from '@axe-core/react';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import type { LDMultiKindContext } from 'launchdarkly-react-client-sdk';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { BotAgentRegex } from 'src/constants';

import {
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  DATADOG_SITE,
  ENVIRONMENT,
  LAUNCH_DARKLY_CLIENT_ID,
  SENTRY_LINK,
  VERSION,
} from './config';
import Root from './containers/Root';
import initalizeGoogleTagManager, { googleTagManagerPushDataLayer } from './helpers/google-tag-manager';

import './theme/fonts.css';
import './theme/global.css';
import { loadWAFEnv } from "src/wafCaptcha/utils";

export const LD_SESSION_KEY = 'ld_session';

// Set to true to enable accessibility logs in the console
const enableAccessibilityLogs = false;

initalizeGoogleTagManager();

Sentry.init({
  dsn: SENTRY_LINK,
  environment: ENVIRONMENT || 'development',
  release: `web-client@${VERSION}`,
  integrations: [new BrowserTracing({ tracingOrigins: ['api.clutch.ca'] })],
  tracesSampleRate: 0.2,
  beforeSend(event, hint) {
    const error = hint?.originalException as any;
    // eslint-disable-next-line no-console
    console.debug(JSON.stringify(error));
    // eslint-disable-next-line no-console
    console.debug(JSON.stringify(error?.stack));

    // Don't report errors that aren't instances of Error and on headless browsers
    if (!(error instanceof Error) && navigator.webdriver) {
      return null;
    }

    if (/.*\/static\/js\/\d+\..+\.chunk\.js$/.test(error?.request)) {
      if (/^Loading chunk \d+ failed\.\n\(timeout: .*/.test(error?.message)) {
        // eslint-disable-next-line no-param-reassign
        event.fingerprint = ['chunking-error', 'timeout'];
      } else if (/^Loading chunk \d+ failed\.\n\(error: .*/.test(error?.message)) {
        // eslint-disable-next-line no-param-reassign
        event.fingerprint = ['chunking-error', 'error'];
      } else if (/^Loading chunk \d+ failed\.\n\(missing: .*/.test(error?.message)) {
        // eslint-disable-next-line no-param-reassign
        event.fingerprint = ['chunking-error', 'missing'];
      }
    }

    return event;
  },
});

// navigator.webdriver is true when browsers being controlled by automation
// prevent web crawlers/scrapers from triggering sessions
if (ENVIRONMENT === 'production' && !navigator.webdriver) {
  datadogRum.init({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: 'web-client',
    env: ENVIRONMENT || 'development',
    version: VERSION,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    startSessionReplayRecordingManually: true,
    allowedTracingUrls: ['https://api.clutch.ca', /https:\/\/.*\.api\.clutch\.ca/],
  });
}

// Accessibility chrome dev tool logger
if (ENVIRONMENT === 'development' && enableAccessibilityLogs) {
  axe(React, ReactDOM, 1000);
}

const history = createBrowserHistory();

history.listen((location, action) =>
  googleTagManagerPushDataLayer({
    type: '@@router/LOCATION_CHANGE',
    payload: {
      location,
      action,
    },
  }),
);

const identifyUser = (isBot: boolean): LDMultiKindContext => {
  /*
    Note that we should only deploy this when all old experiments are closed up since the context structure has changed.
    The structure of our LD context below is a multi context to handle both the session and clutch context.
      Session: this is browser session specific, and will change between devices/incognito/etc
      Clutch: this is a persistent context for logged in users.
    Having both of these will allows us to either split experiments on session or user level.
  */

  // check for an existing context in local storage
  const sessionContextStr = localStorage.getItem(LD_SESSION_KEY);
  let multiContext: LDMultiKindContext | null = JSON.parse(sessionContextStr || 'null');

  // if not found, create a new context with no clutch user attached
  if (!multiContext) {
    multiContext = {
      kind: 'multi',
      session: {
        kind: 'session',
        name: 'No User',
        key: isBot ? 'bot_user' : uuidv4(),
        anonymous: isBot,
      },
    };
    localStorage.setItem(LD_SESSION_KEY, JSON.stringify(multiContext));
  }

  return multiContext;
};

(async () => {
  const isBotUserAgent = BotAgentRegex.test(navigator.userAgent);

  if (isBotUserAgent) Sentry.captureMessage(`Bot detected with User Agent: ${navigator.userAgent}`, Sentry.Severity.Info);

  const LDProvider = await asyncWithLDProvider({
    clientSideID: LAUNCH_DARKLY_CLIENT_ID,
    context: identifyUser(isBotUserAgent),
  });

  await loadWAFEnv();

  ReactDOM.render(
    <Router history={history}>
      <LDProvider>
        <Root />
      </LDProvider>
    </Router>,
    document.getElementById('root'),
  );
})();
