import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

import { theme } from 'src/theme';

const DayItem = ({
  dateString,
  selected,
  handleDateClick,
}: {
  dateString: string;
  selected: boolean;
  handleDateClick: (date: string) => void;
}) => {
  const getDayMonthDate = (dateString: string) => {
    const date = dayjs(dateString);

    const day = date.format('ddd'); // Short weekday
    const month = date.format('MMM'); // Short month
    const dayOfMonth = date.date(); // Day of the month

    return { day, month, dayOfMonth };
  };

  const { day, month, dayOfMonth } = getDayMonthDate(dateString);

  return (
    <Stack
      padding="8px 16px"
      borderRadius="1px"
      minWidth="68px"
      alignItems="center"
      margin="2px"
      sx={{
        cursor: 'pointer',
        outline: selected ? '2px solid' : '1px solid',
        outlineColor: selected ? theme.colors.blackTitle : theme.colors.moonMistGrey,
      }}
      onClick={() => handleDateClick(dateString)}
    >
      <Typography variant="body2" color={theme.colors.blackTitle}>
        {day}
      </Typography>
      <Typography variant="body2" color={theme.colors.blackTitle}>
        {month}
      </Typography>
      <Typography variant="subtitle1">{dayOfMonth}</Typography>
    </Stack>
  );
};

export default DayItem;
