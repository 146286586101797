import { Stack, Typography } from '@mui/material';

import { UberVoucherFooter } from 'src/components/Pages/PrivatePurchaseOfferSchedule/components/UberVoucher';
import { theme } from 'src/theme';

import type { Location } from '../../Scheduler';

const LocationItem = ({ location, distance, isStc = false }: { location: Location; distance: number; isStc?: boolean }) => {
  const isVoucherOffered = location?.uberAvailable;

  return (
    <Stack spacing={1} sx={{ textWrap: 'wrap' }} width={{ base: '100%', sm: '440px' }}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography color={theme.colors.blackTitle}>{location?.name}</Typography>
        <Typography variant="body2" color={theme.colors.blackTitle}>
          ({distance} km away)
        </Typography>
      </Stack>
      <Typography variant="body2">{location?.address?.raw}</Typography>
      {isStc &&
        (isVoucherOffered ? (
          <UberVoucherFooter iconSize="36px" divider={false}>
            <Typography sx={{ color: theme.colors.neutralDarkGrey }} variant="caption">
              $50 voucher
            </Typography>
          </UberVoucherFooter>
        ) : (
          <Typography variant="caption">No voucher</Typography>
        ))}
    </Stack>
  );
};

export default LocationItem;
