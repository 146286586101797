export enum Steps {
  SELL_TO_CLUTCH = 'SELL_TO_CLUTCH',
  BUYER_INFORMATION = 'BUYER_INFORMATION',
  CLUTCH_PLAN = 'CLUTCH_PLAN',
  PAYMENT_TYPE = 'PAYMENT_TYPE',
  BORROWER_INFORMATION = 'BORROWER_INFORMATION',
  ESTIMATE_CREDIT_SCORE = 'ESTIMATE_CREDIT_SCORE',
  FINANCE_CALCULATOR = 'FINANCE_CALCULATOR',
  PROTECTION_CUSTOMIZATION = 'PROTECTION_CUSTOMIZATION',
  WARRANTIES_SELECT = 'WARRANTIES_SELECT',
  GAP_SELECT = 'GAP_SELECT',
  RECEPTION_TYPE = 'RECEPTION_TYPE',
  RECEPTION_TIME = 'RECEPTION_TIME',
  DRIVERS_LICENSE = 'DRIVERS_LICENSE',
  REVIEW = 'REVIEW',
  CONFIRMATION = 'CONFIRMATION',
}
