import { useBooleanState } from '@clutch/hooks';
import { Status } from '@googlemaps/react-wrapper';
import type { TextFieldProps } from '@mui/material';
import { Stack, Box, TextField } from '@mui/material';
import type { ChangeEvent } from 'react';
import { forwardRef } from 'react';
import type { FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import { NumberFormatBase, PatternFormat } from 'react-number-format';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { AddressList } from 'src/components/FormInput/LocationInput/components/AddressList';
import { useAddressSelection } from 'src/components/FormInput/LocationInput/hooks';
import { getFullAddress } from 'src/components/FormInput/LocationInput/utils';
import ImportantBanner from 'src/components/ImportantBanner';
import { ControllerContainer } from 'src/components/molecules/atoms/ControllerContainer';
import GoogleMapsWrapper, { wrapWithGoogleMaps } from 'src/contexts/GoogleMaps/GoogleMapsWrapper';

// All input forms require react hook form provider
// This way we can access the form context without defining it in the component
// It is highly recommended that error message (helperText) is handled OUTSIDE this component
// This component MUST only return the input field (no labels, no error text, etc)

export type GoogleResult = {
  city: string;
  country: string;
  postalCode: string;
  province: string;
  street: string;
  longitude?: number;
  latitude?: number;
  raw?: string;
  provinceCode?: string;
  name?: string;
  apartment?: string;
};

// helpers
const postalCodeMask = (value: string) => {
  const format = (value: string) => {
    const valueNoSpaces = value.replace(/\s/g, '');
    const valueUpperCase = valueNoSpaces.toUpperCase();
    const valueWithSpace = [valueUpperCase.slice(0, 3), valueUpperCase.slice(3, 6)].join(' ');
    const strippedValue = valueWithSpace.trim();
    return strippedValue;
  };

  return format(value);
};

const PostalCodeTextField = ({ formField, googleFormField, sx, size, ...rest }: PostalCodeInputProps) => {
  // contexts and hooks
  const {
    control,
    setValue,
    trigger,
    setError,
    formState: { errors },
  } = useFormContext();

  const searchPlace = async (value: string) => {
    const isPostalCodeValid = await trigger(formField);
    if (!isPostalCodeValid) return;

    try {
      const response = await geocodeByAddress(value);

      const { lat, lng } = await getLatLng(response[0]);
      const fullAddress = getFullAddress({
        response,
        latitude: lat,
        longitude: lng,
      });

      if (!googleFormField) return;

      setValue(googleFormField, fullAddress);
    } catch (error) {
      if (error === 'ZERO_RESULTS' && googleFormField) {
        setValue(googleFormField, undefined);
        setError(formField, { type: 'custom', message: 'No locations found' });
      }
    }
  };

  return (
    <Controller
      name={formField}
      control={control}
      render={({ field }) => (
        <Box width="100%">
          <PlacesAutocomplete
            value={field.value || ''}
            onChange={async value => {
              field.onChange(value);
              await searchPlace(value);

              if (rest.onChange)
                rest.onChange({
                  target: {
                    value: value,
                  },
                } as ChangeEvent<HTMLInputElement>);
            }}
            searchOptions={{
              componentRestrictions: { country: 'CA' },
              location: new google.maps.LatLng({ lat: 0, lng: 0 }),
              radius: 200000,
              types: ['postal_code'],
            }}
            debounce={500}
          >
            {({ getInputProps }) => {
              const googleProps = getInputProps();
              return (
                <NumberFormatBase
                  {...googleProps}
                  {...rest}
                  format={postalCodeMask}
                  removeFormatting={value => value}
                  isValidInputCharacter={char => /^[a-z0-9]$/i.test(char)}
                  getCaretBoundary={value =>
                    Array(value.length + 1)
                      .fill(0)
                      .map(() => true)
                  }
                  onKeyDown={e =>
                    !/^(?:[a-z0-9]|Backspace|Delete|Home|End|ArrowLeft|ArrowRight|Shift|CapsLock|Control|NumLock|Tab|Paste|Redo|Undo)$/i.test(
                      e.key,
                    ) && e.preventDefault()
                  }
                  error={!!errors[formField]}
                  customInput={TextField}
                  fullWidth
                  width="100%"
                  placeholder="Postal Code"
                  value={field.value}
                  onChange={async event => {
                    const value = event.target.value;

                    const formattedValue = postalCodeMask(value);
                    field.onChange(formattedValue);

                    googleProps.onChange({
                      target: {
                        value: formattedValue,
                      },
                    });
                  }}
                  size={size}
                  sx={sx}
                />
              );
            }}
          </PlacesAutocomplete>
        </Box>
      )}
    />
  );
};

type PostalCodeInputProps = Omit<TextFieldProps, 'defaultValue' | 'type'> & {
  formField: string;
  googleFormField?: string;
};
export const PostalCodeInput = forwardRef<HTMLInputElement, PostalCodeInputProps>(
  ({ formField, googleFormField, sx, size, ...rest }: PostalCodeInputProps) => {
    const googleMapRender = (status: Status) => {
      if (status === Status.FAILURE) {
        return (
          <ImportantBanner
            content={'Postal code input error. Please refresh the page and try again.'}
            isError={undefined}
            isInfo={undefined}
            centerVertically={undefined}
          />
        );
      }

      // we only want to render the input field if the google maps api is loaded
      if (status === Status.SUCCESS) {
        return <PostalCodeTextField formField={formField} googleFormField={googleFormField} sx={sx} size={size} {...rest} />;
      }

      return <TextField disabled placeholder="Loading ..." />;
    };

    return <GoogleMapsWrapper render={googleMapRender} />;
  },
);
PostalCodeInput.displayName = 'PostalCodeInput';

type UnwrappedPostalCodeInputProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = Omit<
  TextFieldProps,
  'defaultValue' | 'onChange' | 'type' | 'value'
> &
  UseControllerProps<TFieldValues, TName> & {
    label?: string;
  };

export const UnwrappedPostalCodeInput = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  label,
  control,
  name,
  ...rest
}: UnwrappedPostalCodeInputProps<TFieldValues, TName>) => {
  return (
    <ControllerContainer label={label}>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <NumberFormatBase
            {...field}
            {...rest}
            getCaretBoundary={value =>
              Array(value.length + 1)
                .fill(0)
                .map(() => true)
            }
            value={field.value || ''}
            format={postalCodeMask}
            onChange={event => field.onChange(postalCodeMask(event.target.value))}
            removeFormatting={value => value}
            customInput={TextField}
            data-testid={`text-input-${name}`}
            isValidInputCharacter={char => /^[a-z0-9]$/i.test(char)}
            error={!!error}
            helperText={error?.message}
            label=""
          />
        )}
      />
    </ControllerContainer>
  );
};

type PhoneNumberInputProps = Omit<TextFieldProps, 'defaultValue' | 'onChange' | 'type'> & {
  formField: string;
  label?: string;
  control: any;
};

export const PhoneNumberInput = forwardRef<HTMLInputElement, PhoneNumberInputProps>(
  ({ control, label, formField, ...rest }: PhoneNumberInputProps) => {
    return (
      <ControllerContainer label={label}>
        <Controller
          control={control}
          name={formField}
          render={({ field, fieldState: { error } }) => (
            <PatternFormat
              format="+1 (###) #### ###"
              customInput={TextField}
              onValueChange={values => {
                const { value } = values;
                field.onChange(value);
              }}
              error={!!error}
              helperText={error?.message}
              data-testid={`text-input-${formField}`}
              {...field}
              {...rest}
              onChange={undefined}
              label=""
            />
          )}
        />
      </ControllerContainer>
    );
  },
);
PhoneNumberInput.displayName = 'PhoneNumberInput';

type InputProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = Omit<TextFieldProps, 'value' | 'variant'> &
  UseControllerProps<TFieldValues, TName> & {
    label?: string;
  };

export const TextInput = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  label,
  control,
  name,
  ...rest
}: InputProps<TFieldValues, TName>) => {
  return (
    <ControllerContainer label={label}>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <TextField data-testid={`text-input-${name}`} {...rest} {...field} error={!!error} helperText={error?.message} label="" />
        )}
      />
    </ControllerContainer>
  );
};

type WrappedStreetAddressFieldProps = Omit<TextFieldProps, 'variant'> & {
  label?: string;
  countryCode?: string;
  restrictionTypes?: string[];
  setFullAddress: (values: GoogleResult) => void;
  onChange: (value: string) => void;
  value?: string;
};

const WrappedStreetAddressField = wrapWithGoogleMaps(
  ({ countryCode = 'CA', restrictionTypes = [], setFullAddress, value, onChange, ...rest }: WrappedStreetAddressFieldProps) => {
    const { address, filterByRestrictionTypes, handleSelect, handleAddressChange } = useAddressSelection({
      onChange,
      value,
      restrictionTypes,
      setFullAddress,
    });

    const isFocusedState = useBooleanState();
    return (
      <PlacesAutocomplete
        value={address}
        onChange={handleAddressChange}
        onSelect={handleSelect}
        highlightFirstSuggestion
        searchOptions={{
          componentRestrictions: { country: countryCode },
          location: new google.maps.LatLng({ lat: 0, lng: 0 }),
          radius: 200000,
          types: ['address'],
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <Stack position="relative" width={1}>
            <TextField
              {...getInputProps()}
              type="search"
              // To avoid address mismatches, disable autofill for location search to force select from google placesautocomplete options
              // disable autofill for chrome as per https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
              autoComplete="do-not-autofill-location"
              // disable autofill for safari as per https://bytes.grubhub.com/disabling-safari-autofill-for-a-single-line-address-input-b83137b5b1c7
              name="searchLocation"
              {...rest}
              onFocus={isFocusedState.setTrue}
              onBlur={isFocusedState.setFalse}
            />
            {isFocusedState.value && (
              <AddressList
                results={suggestions}
                isLoading={loading}
                filterByRestrictionTypes={filterByRestrictionTypes}
                getSuggestionItemProps={getSuggestionItemProps}
              />
            )}
          </Stack>
        )}
      </PlacesAutocomplete>
    );
  },
);

type WrappedStreetAddressInputProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = Omit<
  WrappedStreetAddressFieldProps,
  'onChange'
> &
  UseControllerProps<TFieldValues, TName> & {
    label?: string;
  };

export const WrappedStreetAddressInput = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  label,
  control,
  name,
  ...rest
}: WrappedStreetAddressInputProps<TFieldValues, TName>) => {
  return (
    <ControllerContainer label={label}>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <WrappedStreetAddressField
            {...field}
            {...rest}
            onChange={(val: string) => field.onChange(val)}
            data-testid={`text-input-${name}`}
            error={!!error}
            helperText={error?.message}
            label=""
          />
        )}
      />
    </ControllerContainer>
  );
};
