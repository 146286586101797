/* eslint-disable func-names */
import { combineProviders } from '@clutch/hooks';
import { ToastProvider } from '@clutch/torque-ui';
import { withLDConsumer, useFlags } from 'launchdarkly-react-client-sdk';

import { FB_APP_ID } from '../../config';
import {
  AuthProvider,
  TestingProvider,
  PromoProvider,
  SiteHeaderProvider,
  VehicleDetailsProvider,
  VehicleLookupsProvider,
  LoginSignupModalProvider,
  FinancingProvider,
  PrivatePurchaseProvider,
  PrivatePurchaseShellProvider,
  LastUserProvider,
  CheckoutProvider,
  AnalyticsProvider,
  LocationProvider,
  PrivatePurchaseOfferProvider,
  SavedSearchProvider,
  FavouritesDrawerProvider,
  ReferralProvider,
} from '../../contexts';
import { useLDCacheBusting } from '../../hooks';
import { ClutchThemeProvider } from '../../theme';
import App from '../App';
import Maintenance from '../Maintenance';
import { AWSWAFCaptchaModal } from "src/wafCaptcha";

const Providers = combineProviders([
  ClutchThemeProvider,
  ToastProvider,
  AuthProvider,
  TestingProvider,
  LocationProvider,
  AnalyticsProvider,
  VehicleDetailsProvider,
  VehicleLookupsProvider,
  CheckoutProvider,
  LastUserProvider,
  LoginSignupModalProvider,
  PrivatePurchaseOfferProvider,
  PrivatePurchaseProvider,
  PrivatePurchaseShellProvider,
  PromoProvider,
  SiteHeaderProvider,
  FinancingProvider,
  SavedSearchProvider,
  FavouritesDrawerProvider,
  ReferralProvider,
]);

const Root = () => {
  const flags = useFlags();

  window.fbAsyncInit = function () {
    window.FB.init({
      appId: FB_APP_ID,
      cookie: true,
      xfbml: true,
      version: 'v2.7',
    });

    window.FB.AppEvents.logPageView();
  };

  // inserts facebook sdk into before the first script tag
  const addFacebookScript = () => {
    if (document.getElementById('facebook-jssdk')) return;

    const scriptElement = document.getElementsByTagName('script')[0];
    const newScriptElem = document.createElement('script');
    newScriptElem.setAttribute('id', 'facebook-jssdk');
    newScriptElem.setAttribute('src', 'https://connect.facebook.net/en_US/sdk.js');
    scriptElement.parentNode.insertBefore(newScriptElem, scriptElement);
  };
  addFacebookScript();

  useLDCacheBusting({ flags });

  if (flags?.ecsGlobalMaintenance) {
    return <Maintenance />;
  }

  return (
    <Providers>
      <App />
      <AWSWAFCaptchaModal/>
    </Providers>
  );
};

export default withLDConsumer()(Root);
