import styled from 'styled-components';
import { Link } from 'react-router-dom';

const VehicleCardLink = styled(Link)`
  flex: 1;
  color: inherit;
  font-weight: inherit;
  &:hover {
    color: inherit;
  }
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none !important;
  }
  width: 100%;
`;

export default VehicleCardLink;
