import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { theme } from '../../../../../theme';

const SaleContainer = styled(Box)(() => ({
  padding: '4px 8px',
  backgroundColor: theme.colors.blackTitle,
}));
export const Sale = () => {
  return (
    <SaleContainer>
      <Typography color={theme.colors.white} variant="body2" fontWeight={500}>
        SALE
      </Typography>
    </SaleContainer>
  );
};
