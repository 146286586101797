import { useBooleanState } from '@clutch/hooks';
import { Box, Grid, MenuItem, Select, Stack, Typography } from '@mui/material';

import type { Address } from 'src/components/AddressForm/types/address';
import { theme } from 'src/theme';

import DayItem from './components/DayItem';
import LocationItem from './components/LocationItem';
import ScheduleCallSupport from './components/ScheduleCallSupport';
import TimeItem from './components/TimeItem';

export type Location = {
  id: string;
  name: string;
  address: Address;
  uberAvailable: boolean;
};

type SchedulerProps = {
  height: string;
  locationLabel: JSX.Element | string;
  locations: { location: Location; distance: number; locationId: string }[];
  selectedLocation: { location: Location; distance: number; locationId: string };
  dayTimeSlotMap: { [key: string]: { time: string; available: boolean }[] };
  selectedDate: string;
  selectedTime: string;
  handleLocationClick: (locationId: string) => void;
  handleDateClick: (date: string) => void;
  handleTimeClick: (time: string) => void;
  isStc?: boolean;
  isModal?: boolean;
  showEarlierDatesCopy?: boolean;
};

// Reusable scheduler calendar component
// for STC and Checkout flows
const Scheduler = ({
  height,
  locationLabel,
  locations,
  selectedLocation,
  selectedDate,
  selectedTime,
  dayTimeSlotMap,
  handleLocationClick,
  handleDateClick,
  handleTimeClick,
  isStc = false,
  isModal = false,
  showEarlierDatesCopy = false,
}: SchedulerProps) => {
  const locationDropDownState = useBooleanState({ initialState: false });

  const isSingleLocation = locations?.length === 1;

  // Only one location is available for selection
  const singleLocationRender = () => {
    return (
      <Stack spacing={1}>
        {isStc ? (
          <Typography variant="body2">{selectedLocation.location.address?.raw}</Typography>
        ) : (
          <>
            <Typography color={theme.colors.blackTitle}>
              {selectedLocation.location.name} ({selectedLocation.distance} km away)
            </Typography>
            <Typography variant="body2">{selectedLocation.location.address?.raw}</Typography>
          </>
        )}
      </Stack>
    );
  };

  // Multiple locations are available for selection
  const multipleLocationsRender = () => {
    return (
      <Select
        displayEmpty={false}
        value={selectedLocation}
        renderValue={() => <LocationItem location={selectedLocation.location} distance={selectedLocation.distance} isStc={isStc} />}
        open={locationDropDownState.value}
        onOpen={locationDropDownState.setTrue}
        onClose={locationDropDownState.setFalse}
        sx={muiTheme => ({ height: 'auto', width: '440px', [muiTheme.breakpoints.down('sm')]: { width: '100%' } })}
        MenuProps={{
          MenuListProps: { disablePadding: true },
          sx: {
            '.MuiMenu-paper': {
              boxShadow: 'none',
              border: '1px solid',
              borderColor: theme.colors.moonMistGrey,
            },
          },
        }}
      >
        {locations && locations.length > 1 ? (
          locations.map(
            ({ location, distance }: { location: Location; distance: number }) =>
              location.id != selectedLocation.locationId && (
                <MenuItem
                  key={'dropoff-' + location.id}
                  sx={{
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    width: { base: '100%', sm: '440px' },
                  }}
                  onClick={() => handleLocationClick(location.id)}
                >
                  <LocationItem location={location} distance={distance} isStc={isStc} />
                </MenuItem>
              ),
          )
        ) : (
          <MenuItem
            disabled
            sx={{
              paddingX: 2,
              paddingY: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            No other locations
          </MenuItem>
        )}
      </Select>
    );
  };

  return (
    <>
      <Grid
        container
        border={{
          md: isModal ? 'none' : '1px solid',
        }}
        borderRadius={{
          md: isModal ? 0 : 2,
        }}
        borderColor={{
          md: theme.colors.moonMistGrey,
        }}
        height={{ md: height }}
        maxWidth="inherit"
        overflow="hidden"
      >
        <Grid item base={12} md={8}>
          <Stack spacing="36px" padding={{ base: 0, md: isModal ? 0 : 4 }}>
            <Stack spacing={3}>
              <Typography
                variant="subtitle2"
                sx={muiTheme => ({
                  [muiTheme.breakpoints.down('md')]: {
                    fontSize: '16px',
                    lineHeight: '24px',
                  },
                })}
              >
                {locationLabel}
              </Typography>
              {isSingleLocation ? singleLocationRender() : multipleLocationsRender()}
            </Stack>
            <Stack spacing={2}>
              <Stack direction={{ base: 'column', sm: 'row' }} alignItems={{ base: 'unset', sm: 'end' }}>
                <Typography
                  variant="subtitle2"
                  display="inline"
                  sx={muiTheme => ({
                    [muiTheme.breakpoints.down('md')]: {
                      fontSize: '16px',
                      lineHeight: '24px',
                    },
                  })}
                >
                  Select a date
                </Typography>
                {showEarlierDatesCopy && (
                  <Typography
                    variant="body2"
                    display="inline"
                    sx={{ lineHeight: '20px', marginLeft: { base: 0, sm: '12px' }, marginTop: { base: '8px', sm: 0 } }}
                  >
                    Want earlier dates? Try a different location!
                  </Typography>
                )}
              </Stack>
              <Stack direction="row" spacing={2} overflow="scroll" alignItems="center" sx={{ scrollbarWidth: 'none' }} padding="2px">
                {Object.entries(dayTimeSlotMap || {}).map(([date]) => (
                  <DayItem key={'date-' + date} dateString={date} selected={date === selectedDate} handleDateClick={handleDateClick} />
                ))}
              </Stack>
            </Stack>
            <Box display={{ base: 'none', md: 'block' }}>
              <ScheduleCallSupport />
            </Box>
          </Stack>
        </Grid>
        {selectedDate && (
          <Grid
            item
            base={12}
            md={4}
            alignItems="center"
            borderLeft={{ md: '1px solid' }}
            borderColor={{ md: theme.colors.moonMistGrey }}
            height="inherit"
            marginTop={{ base: '36px', md: 0 }}
          >
            <Typography
              variant="subtitle2"
              display={{ md: 'none' }}
              sx={muiTheme => ({
                [muiTheme.breakpoints.down('md')]: {
                  fontSize: '16px',
                  lineHeight: '24px',
                },
              })}
            >
              Select a time
            </Typography>

            <Stack
              direction={{ base: 'row', md: 'column' }}
              spacing={2}
              padding={{ base: '2px', md: 4 }}
              alignItems="center"
              height="inherit"
              overflow={'scroll'}
              marginTop={{ base: 2, md: 0 }}
            >
              {dayTimeSlotMap?.[selectedDate]?.map(({ time }: { time: string }) => (
                <TimeItem key={'time-' + time} timeString={time} selected={time === selectedTime} handleTimeClick={handleTimeClick} />
              ))}
            </Stack>
          </Grid>
        )}
      </Grid>

      <Box display={{ md: 'none' }} marginTop={6}>
        <ScheduleCallSupport />
      </Box>
    </>
  );
};

export default Scheduler;
