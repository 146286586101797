import * as Sentry from '@sentry/browser';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as R from 'ramda';
import { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import CheckoutApi from 'src/api/checkout';
import { type Protection, ProtectionTypeEnum } from 'src/api/clutchPackages';
import RetailCheckoutApi from 'src/api/retailCheckout';
import { useClutchPlans, useReceptionTypes } from 'src/api/swr/useRetailCheckout';
import { type WarrantyAncillary } from 'src/api/warranties';
import { AncillaryType, Modals, ProviderType } from 'src/constants';
import { usePrivatePurchases } from 'src/containers/Checkout/RetailCheckout/hooks';
import { useTrace } from 'src/hooks';
import usePrivatePurchase from 'src/hooks/usePrivatePurchase';
import { ERROR_CODES, ERROR_MODALS_MAP, ROUTES } from 'src/static';
import { useModal } from 'src/stores';

import { CheckoutContext } from '../checkout';
import type {
  OnContinueOpts,
  RetailCheckoutContextProps,
  RetailCheckoutContextValues,
  UpdateDeliveryAddressOpts,
} from './RetailCheckoutContext.types';
import { RetailCheckoutSteps, RetailCheckoutStepTree, RetailEventMap, getEarliestPickupDate } from './utils';

dayjs.extend(isSameOrBefore);

// cast the null to any to avoid having to cast the context each time its used
export const RetailCheckoutContext = createContext<RetailCheckoutContextValues>(null as any);

export const RetailCheckoutProvider = ({ children }: RetailCheckoutContextProps) => {
  const {
    retailFlow,
    answers: checkout,
    setAnswers: setCheckout,
    vehicle,
    financeCalculator,
    setActiveErrorModal,
    setSessionEndTime,
    trackEvent,
    trackSTCOfferCreatedEvent,
    clutchCare,
    setPrivatePurchase,
    privatePurchase,
    loadCheckout,
    isClutchPlanCheckout,
    warrantyProvider,
    isCheckoutWarrantyProviderLoading,
  } = useContext(CheckoutContext);

  const history = useHistory();
  const modalState = useModal();
  const trace = useTrace();
  const [isContinueLoading, setIsContinueLoading] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const privatePurchasesHook = usePrivatePurchases();
  const { activeStep, nextStep, navigateToSection, previousStep } = retailFlow;
  const beginSTCFlow = activeStep?.navigationParams?.beginSTCFlow || !privatePurchasesHook.privatePurchases.length;

  const privatePurchaseHook = usePrivatePurchase({
    financeCalculator,
    nextStep,
    setPrivatePurchase,
    checkoutId: checkout?.id,
    trackSTCOfferCreatedEvent,
  });

  const { data: clutchPlans, isLoading: isClutchPlansLoading, mutate: reloadClutchPlans } = useClutchPlans(checkout?.id);

  const {
    data: receptionTypes,
    isLoading: isReceptionTypesLoading,
    mutate: reloadReceptionTypes,
  } = useReceptionTypes(checkout?.id, checkout?.deliveryDetails?.address);

  const getPaymentDetails = async () => {
    const payment = await CheckoutApi.getPaymentMethodDetails({ checkoutId: checkout?.id });
    setPaymentDetails(payment?.data || null);
  };

  const updateDeliveryAddress = async (options: UpdateDeliveryAddressOpts) => {
    const { deliveryAddress, confirmed = false } = options;

    try {
      const updatedCheckout = await RetailCheckoutApi.updateDeliveryAddress({ checkoutId: checkout?.id, deliveryAddress, confirmed });

      setCheckout(updatedCheckout?.data);
    } catch (error: Record<string, any> | any) {
      const errorCode = error?.response?.data?.code as keyof typeof ERROR_MODALS_MAP;

      if (errorCode === ERROR_CODES.VEHICLE_LOCKED) {
        setActiveErrorModal(ERROR_MODALS_MAP.VEHICLE_LOCKED_DURING_CHECKOUT);
      } else if (errorCode === ERROR_CODES.ORDER_LOCATION_CHANGED) {
        modalState.setActiveModal(Modals.RETAIL_CHECKOUT_RESELECT_PACKAGE);
      } else if (ERROR_MODALS_MAP[errorCode]) {
        setActiveErrorModal({ ...ERROR_MODALS_MAP[errorCode], payload: deliveryAddress });
      } else {
        setActiveErrorModal({ ...ERROR_MODALS_MAP.OOPS });

        trace.report({
          type: 'error',
          actionName: 'Failed to update customer delivery address in checkout',
          error,
          data: { deliveryAddress, confirmed },
        });
      }
    }
  };

  const onContinue = async (options: OnContinueOpts) => {
    const { payload, trackingPayload, stepKey, queryParams, ignoreNextStep } = options;

    try {
      setIsContinueLoading(true);
      const { data } = await RetailCheckoutApi.updateCheckout({
        payload,
        checkoutId: checkout.id,
        stepKey: stepKey || activeStep.key,
        queryParams,
      });

      if (!RetailCheckoutSteps[data?.nextStep as RetailCheckoutSteps]) {
        throw new Error(`We don't have a screen for step ${data?.nextStep} yet.`);
      }

      const activeStepEvent = RetailEventMap[activeStep.key as keyof typeof RetailEventMap];
      if (activeStepEvent) {
        trackEvent({
          event: {
            ...activeStepEvent,
            action: 'Click',
            flow: 'checkout',
            payload: trackingPayload || {},
          },
        });

        // Sending BOS params in custom action to Datadog,
        // to help debug any front-end pricing inconsistencies
        trace.report({
          type: 'info',
          actionName: activeStepEvent.name,
          data: financeCalculator?.bosParams,
        });
      }

      if (activeStep.key === RetailCheckoutSteps.BORROWER_INFORMATION && data?.checkout?.creditCheckDetails?.estimatedInterestRate) {
        financeCalculator.isQualifiedState.setTrue();
        financeCalculator.setAprRate(data.checkout.creditCheckDetails.estimatedInterestRate);
        financeCalculator.setNewSuggestedMinDownPayment();
      }

      if (!ignoreNextStep && data.nextStep === RetailCheckoutSteps.CONFIRMATION) {
        setSessionEndTime(0);
        await getPaymentDetails();
      }

      if (!ignoreNextStep) {
        nextStep({ nextStepKey: data.nextStep, navigationParams: { beginSTCFlow: false } });
      }

      // update flow type if user entered info causes us to switch between clutch package checkout and regular checkout
      // right now this only happens at BuyerInformation so we don't have to re-initialize the flow
      if (data.hasClutchPlans !== undefined) {
        isClutchPlanCheckout.setState(data.hasClutchPlans);
      }

      setCheckout(data?.checkout || {});
    } catch (error: Record<string, any> | any) {
      const errorCode = error?.response?.data?.code as keyof typeof ERROR_MODALS_MAP;

      if (errorCode === ERROR_CODES.VEHICLE_LOCKED) {
        setActiveErrorModal(ERROR_MODALS_MAP.VEHICLE_LOCKED_DURING_CHECKOUT);
      } else if (errorCode === ERROR_CODES.ORDER_LOCATION_CHANGED) {
        modalState.setActiveModal(Modals.RETAIL_CHECKOUT_PROVINCE_CHANGE_CONFIRMATION);
      } else if (ERROR_MODALS_MAP[errorCode]) {
        setActiveErrorModal({ ...ERROR_MODALS_MAP[errorCode], payload: options.payload });
      } else {
        setActiveErrorModal({ ...ERROR_MODALS_MAP.OOPS });
        Sentry.captureException(error);
      }
    } finally {
      setIsContinueLoading(false);
    }
  };

  const onBack = () => {
    // when they press back on the first step, take them back to VDP
    if (retailFlow.activeStep.key === checkout?.progressPath?.[0]) {
      return history.push(`${ROUTES.VEHICLE_DETAILS.replace(':vehicleId', vehicle?.id)}`);
    }

    retailFlow.previousStep({ progressPath: checkout?.progressPath });
  };

  const onBackSTC = () => {
    if (checkout?.progressPath?.[0]) {
      // Includes the STC static steps in the progress path to calculate last step
      const progressPath = [checkout.progressPath[0]];
      Object.values(RetailCheckoutStepTree).forEach(step => {
        if (step.isStatic) {
          progressPath.push(step.key);
        }
      });

      // SOF-1080 TODO: add ability to edit a trade-in from within checkout
      if (activeStep?.key === RetailCheckoutSteps.STC_RESULT) {
        navigateToSection({ stepKey: RetailCheckoutSteps.SELL_TO_CLUTCH, progressPath });
      } else {
        previousStep({
          progressPath: progressPath.concat(checkout.progressPath.slice(1)),
          // Set to true for the case we go from STCDetails to SELL_TO_CLUTCH
          navigationParams: { beginSTCFlow: true },
        });
      }
    }
  };

  useEffect(() => {
    loadCheckout();
  }, []);

  const warrantyOptionsAvailable = !R.isEmpty(clutchCare?.allRecommendedWarrantyOptions);

  let defaultTireAndRimProtection, defaultRustProtection;
  if (warrantyProvider === ProviderType.FIRST_CANADIAN) {
    // For now, we are defaulting to adding the 3-year Tire/Rim protection to Certified+ packages.
    defaultTireAndRimProtection = clutchCare?.warrantyAncillaries?.find(
      (protection: Protection) =>
        protection.protectionType?.protectionTypeEnum === ProtectionTypeEnum.TIRE_AND_RIM && protection.packageConfiguration?.default,
    );

    // For now, we are defaulting to adding the 6-year Rust Module protection
    // to Certified+ orders if the vehicle being purchased falls within the age cutoff range.
    // If the vehicle is older than the cutoff age, we default to adding the Basic Rust Module package instead.
    const defaultRustModule = clutchCare?.warrantyAncillaries?.find(
      (protection: Protection) =>
        protection.protectionType?.protectionTypeEnum === ProtectionTypeEnum.RUST && protection.packageConfiguration?.default,
    );
    const backupRustModule = clutchCare?.warrantyAncillaries?.find(
      (protection: Protection) =>
        protection.protectionType?.protectionTypeEnum === ProtectionTypeEnum.RUST && protection.packageConfiguration?.backup,
    );

    const isVehicleEligibleForDefaultRustProtection = !defaultRustModule?.vehicleAgeCutoff
      ? true
      : dayjs()
          .startOf('year')
          .subtract(defaultRustModule.vehicleAgeCutoff, 'year')
          .isSameOrBefore(
            dayjs()
              .startOf('year')
              .year(vehicle?.year),
          );

    defaultRustProtection = isVehicleEligibleForDefaultRustProtection ? defaultRustModule : backupRustModule;
  } else {
    defaultTireAndRimProtection = clutchCare?.warrantyAncillaries?.find(
      (ancillary: WarrantyAncillary) => ancillary.ancillaryType === AncillaryType.TIRE_AND_RIM,
    );
    defaultRustProtection = clutchCare?.warrantyAncillaries?.find(
      (ancillary: WarrantyAncillary) => ancillary.ancillaryType === AncillaryType.RUST,
    );
  }

  const earliestPickupDate = getEarliestPickupDate({ pickupLocations: receptionTypes?.pickup?.pickupLocations || [] });

  return (
    <RetailCheckoutContext.Provider
      value={{
        privatePurchase,
        onContinue,
        onBack,
        trackEvent,
        navigateToSection,
        setActiveErrorModal,
        checkout,
        vehicle,
        activeStep,
        clutchCare,
        financeCalculator,
        paymentDetails,
        isContinueLoading,
        privatePurchaseHook,
        nextStep,
        previousStep,
        onBackSTC,
        clutchPlans,
        isClutchPlansLoading,
        reloadClutchPlans,
        privatePurchasesHook,
        beginSTCFlow,
        warrantyOptionsAvailable,
        defaultRustProtection,
        defaultTireAndRimProtection,
        warrantyProvider,
        isCheckoutWarrantyProviderLoading,
        updateDeliveryAddress,
        receptionTypes,
        isReceptionTypesLoading,
        reloadReceptionTypes,
        earliestPickupDate,
      }}
    >
      {children}
    </RetailCheckoutContext.Provider>
  );
};
