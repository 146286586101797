import VehicleImage from '../VehicleImage';
import { VehicleInformation } from './components/VehicleInformation';
import { VehicleRibbons } from './components/VehicleRibbons';
import * as Styled from './VehicleCard.styles';

type VehicleCardProps = {
  photoUrl: string;
  vehicle: any;
  renderLikeButton?: any;
  handleClick?: any;
  isSale?: boolean;
  previousPrice?: number | null;
  showShippingFee?: boolean;
  fullHeight?: boolean;
};

export const VehicleCard = ({
  photoUrl,
  vehicle,
  handleClick = () => {},
  renderLikeButton = () => {},
  isSale = false,
  previousPrice,
  showShippingFee = true,
  fullHeight,
}: VehicleCardProps) => (
  <Styled.VehicleCard onClick={handleClick} fullHeight={fullHeight}>
    <VehicleRibbons showShippingFee={showShippingFee} vehicle={vehicle} isSale={!!previousPrice && isSale} />
    <VehicleImage hasBorderRadius vehicle={vehicle} renderLikeButton={renderLikeButton} url={photoUrl} />
    <VehicleInformation vehicle={vehicle} isSale={isSale} previousPrice={previousPrice} />
  </Styled.VehicleCard>
);
