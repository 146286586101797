import type { AxiosError } from 'axios';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';

import { useTrace } from 'src/hooks';

import type { PrivatePurchaseOffer } from '../privatePurchase';
import { swrFetcher } from './utils/swrFetcher';

export type SWRAuthRequired = { url: string; userId: string } | null;

export enum BannerType {
  DEFAULT_BANNER = 'DEFAULT_BANNER',
  ACTIVE_REFERRAL_BANNER = 'ACTIVE_REFERRAL_BANNER',
  STC_OFFER_BANNER = 'STC_OFFER_BANNER',
}

export type UserPrivatePurchaseDetails = {
  latestPrivatePurchase?: PrivatePurchaseOffer;
  privatePurchasesCount: number;
};

export type PrivatePurchaseBanner = {
  id: string;
  year: string;
  make: string;
  model: string;
  series: string;
  style: string;
  expiredAt: string;
};

export type ActiveReferralBanner = {
  createdAt: string;
  id: string;
  provinceId: string;
  refereeReward: number;
  referrerBonus: number;
  type: string;
  updatedAt: string;
};

export type UserBannerDetails = {
  type: BannerType;
  data: ActiveReferralBanner | PrivatePurchaseBanner;
};

export const isActiveReferralBanner = (data: ActiveReferralBanner | PrivatePurchaseBanner): data is ActiveReferralBanner => {
  return (data as ActiveReferralBanner).refereeReward !== undefined;
};

export const isPrivatePurchaseBanner = (data: ActiveReferralBanner | PrivatePurchaseBanner): data is PrivatePurchaseBanner => {
  return (data as PrivatePurchaseBanner).year !== undefined;
};

export const useUserBanner = (isAuthenticated: boolean, userId: string) => {
  const trace = useTrace();
  const url = '/users/me/banner';
  return useSWRImmutable<UserBannerDetails, any, SWRAuthRequired | null, any>(
    isAuthenticated ? { url, userId } : null,
    ({ url }) => swrFetcher(url),
    {
      onError: (error: AxiosError) => {
        trace.report({
          type: 'error',
          actionName: 'Failed to get user banner',
          error,
          data: {},
        });
      },
      shouldRetryOnError: false,
    },
  );
};

export const useUserLatestPrivatePurchase = (isAuthenticated: boolean, userId: string) => {
  const trace = useTrace();
  const url = '/users/me/privatePurchases/latest';
  return useSWR<UserPrivatePurchaseDetails, any, SWRAuthRequired | null, any>(
    isAuthenticated ? { url, userId } : null,
    ({ url }) => swrFetcher(url),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      onError: (error: AxiosError) => {
        trace.report({
          type: 'error',
          actionName: 'Failed to get user latest private purchase',
          error,
          data: {},
        });
      },
    },
  );
};

export const useUserPrivatePurchases = (isAuthenticated: boolean, userId: string) => {
  const trace = useTrace();
  const url = '/users/me/privatePurchases';
  return useSWR<PrivatePurchaseOffer[]>(isAuthenticated ? { url, userId } : null, ({ url }) => swrFetcher(url), {
    shouldRetryOnError: false,
    onError: error => {
      trace.report({
        type: 'error',
        actionName: 'Failed to get users private purchases',
        error,
        data: {},
      });
    },
  });
};
