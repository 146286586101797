import { ArrowBack, Close } from '@mui/icons-material';
import type { BoxProps, DrawerProps, ModalProps as MuiModalProps } from '@mui/material';
import { Box, Drawer, Fade, Modal as MuiModal, Portal, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import type React from 'react';

import { theme } from 'src/theme';

import type { ProgressProps } from './atoms/Progress';
import { Progress } from './atoms/Progress';

type ModalContainerProps = DrawerProps & MuiModalProps & { containerProps?: BoxProps; drawerDown?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' };
const ModalContainer = ({ children, open, onClose, sx, containerProps = {}, drawerDown = 'sm', ...rest }: ModalContainerProps) => {
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down(drawerDown));

  const borderRadius = containerProps.borderRadius || '10px';

  if (isMobile)
    return (
      <Drawer
        open={open}
        onClose={onClose}
        anchor="bottom"
        sx={{ ...sx }}
        {...rest}
        PaperProps={{ sx: { borderTopLeftRadius: borderRadius, borderTopRightRadius: borderRadius }, ...containerProps }}
      >
        {children}
      </Drawer>
    );

  return (
    <MuiModal open={open} onClose={onClose} {...rest} closeAfterTransition sx={{ ...sx }}>
      <Fade in={open}>
        <Box
          display="flex"
          sx={{ overflowY: 'hidden', background: theme.colors.white, maxHeight: 'calc(100vh - 32px)' }}
          borderRadius={borderRadius}
          {...containerProps}
        >
          {children}
        </Box>
      </Fade>
    </MuiModal>
  );
};
ModalContainer.displayName = 'ModalContainer';

export type ModalProps = ModalContainerProps &
  Partial<ProgressProps> & {
    multistep?: boolean;
    onBack?: () => void;
    bodyPadding?: string;
  };

/**
 *@param: {boolean} multistep - A boolean to tell if the modal is a multistep modal. (requires steps and currentStep to be passed as well)
 *@param: {number} steps - The number of steps in the multistep modal.
 *@param: {number} currentStep - The current step multistep (1-indexed).
 * @param: {function} onBack - The back button is displayed when a onBack function for multistep modal
 */
export const Modal = ({
  bodyPadding = '32px 24px',
  open,
  onClose,
  title,
  multistep,
  onBack,
  steps,
  currentStep,
  children,
  ...rest
}: ModalProps) => {
  const isMultistep = multistep && steps && currentStep;

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClose) onClose(event, 'backdropClick');
  };

  return (
    <Portal>
      <ModalContainer open={open} onClose={onClose} disableAutoFocus {...rest}>
        <Stack width={1}>
          {/* Header */}
          <Stack width={1} position="sticky" top="0px" zIndex={551}>
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              padding={isMultistep ? '32px 24px' : '24px'}
              borderBottom={isMultistep ? 'none' : `1px solid ${theme.colors.whisperGrey}`}
              sx={{ background: theme.colors.white }}
            >
              {/* If multistep we render a box to center the title even when not rendering the back button */}
              {isMultistep && (
                <Box>
                  {onBack && (
                    <IconButton
                      aria-label="back"
                      onClick={onBack}
                      sx={{ color: theme.colors.mediumBlack, '&:hover': { boxShadow: 'none' }, p: 0 }}
                      size="medium"
                    >
                      <ArrowBack />
                    </IconButton>
                  )}
                </Box>
              )}
              <Typography variant="subtitle2">{title}</Typography>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{ color: theme.colors.mediumBlack, '&:hover': { boxShadow: 'none' }, p: 0 }}
                size="medium"
              >
                <Close />
              </IconButton>
            </Stack>
            {isMultistep && (
              <Box padding="0px 24px">
                <Progress steps={steps} currentStep={currentStep} />
              </Box>
            )}
          </Stack>

          {/* Body */}
          <Box padding={bodyPadding} sx={{ overflowY: 'scroll', overflowX: 'hidden' }}>
            <Box>{children}</Box>
          </Box>
        </Stack>
      </ModalContainer>
    </Portal>
  );
};

Modal.displayName = 'Modal';
