import { Box, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { type ClutchPackage, type PackageWarranty } from 'src/api/clutchPackages';
import { CheckoutContext } from 'src/contexts';
import { RetailCheckoutContext } from 'src/contexts/retailCheckout';
import { MoneyMask } from 'src/helpers/masks';
import type { UseFinanceProps } from 'src/hooks/useFinanceForPlanPricing';
import useFinanceForPlanPricing from 'src/hooks/useFinanceForPlanPricing';
import { theme } from 'src/theme';
import { financing } from '@clutch/clutch-common/lib/utils';
const { constants: FinancingConstants } = financing;

export const defaultInterestRate = FinancingConstants.DEFAULT_VALUES.APR

type PlanPriceProps = {
  clutchPlans: ClutchPackage[]; // Used for calculating all clutchPlans being shown
  clutchPlan: ClutchPackage; // The clutch plan to be displayed
  selectedWarranty: PackageWarranty | null;
  deliveryFee?: string | null;
  isFinancing: boolean;
  showTradeInSavings?: boolean;
};
const PlanPrice = ({ clutchPlans, clutchPlan, selectedWarranty, isFinancing, showTradeInSavings = true }: PlanPriceProps) => {
  const { paymentSummary } = useContext(CheckoutContext);
  const { checkout, vehicle, warrantyOptionsAvailable, defaultRustProtection, defaultTireAndRimProtection } =
    useContext(RetailCheckoutContext);

  const [financingPayments, setFinancingPayments] = useState<any>();
  const [clutchPlanSubtotal, setClutchPlanSubtotal] = useState(0);
  const [clutchPlanSubtotalWithTradeIn, setClutchPlanSubtotalWithTradeIn] = useState(0);
  const [tradeInCredit, setTradeInCredit] = useState(0);
  const [finalPriceIncFees, setFinalPriceIncFees] = useState(0);

  const lienAmount = paymentSummary.privatePurchase?.priceDetails?.lienAmount;
  const useFinanceParams: UseFinanceProps = {
    tradeInPrice: paymentSummary.privatePurchase?.priceDetails?.vehicleValue,
    tradeInTaxSavingsInput: paymentSummary.privatePurchase?.priceDetails?.taxSavings,
    isFinancing,
    // hard coded interest rate for clutch plans view
    interestRateInput: defaultInterestRate,
    selectedWarranty,
    lienAmount,
    vehicle: vehicle,
    checkout: checkout,
    clutchPlans: clutchPlans,
    warrantyOptionsAvailable,
    defaultRustProtection,
    defaultTireAndRimProtection,
  };

  const { allPlanPricing, isAnyPositiveEquity } = useFinanceForPlanPricing(useFinanceParams);

  useEffect(() => {
    if (allPlanPricing) {
      const { financingPayments, clutchPlanSubtotal, clutchPlanSubtotalWithTradeIn, finalPriceIncFees, tradeInCredit } =
        allPlanPricing[clutchPlan.name];
      setFinancingPayments(financingPayments);
      setClutchPlanSubtotal(clutchPlanSubtotal);
      setClutchPlanSubtotalWithTradeIn(clutchPlanSubtotalWithTradeIn);
      setFinalPriceIncFees(finalPriceIncFees);
      setTradeInCredit(tradeInCredit);
    }
  }, [clutchPlans]);

  const isTradeInAvailable = paymentSummary?.privatePurchase && clutchPlan.isTradeInAvailable;
  const isVehicleHold = tradeInCredit > clutchPlanSubtotal;
  const isTradeInApplied = checkout.privatePurchases.length > 0 && tradeInCredit > 0;

  const getDisplayedPricing = () => {
    if (isAnyPositiveEquity) {
      return finalPriceIncFees > 0 || !isTradeInAvailable ? (
        <Stack direction="row" alignItems="center" spacing="6px">
          <Typography>You pay us</Typography>
          <Typography variant="subtitle1" color={theme.colors.blackTitle}>
            {MoneyMask(Math.abs(!isTradeInAvailable ? clutchPlanSubtotal : finalPriceIncFees))}
          </Typography>
        </Stack>
      ) : (
        <Stack direction="row" alignItems="center" spacing="6px">
          <Typography>We pay you</Typography>
          <Typography variant="subtitle1" color={theme.colors.blackTitle}>
            {MoneyMask(Math.abs(finalPriceIncFees))}
          </Typography>
        </Stack>
      );
    }

    return isFinancing ? (
      <Stack direction="row" spacing={1} justifyContent="start">
        {isTradeInAvailable && isTradeInApplied && (
          <Typography
            variant="subtitle1"
            fontWeight={400}
            color={theme.colors.neutralDarkGrey}
            sx={{ textDecoration: 'line-through', textDecorationThickness: '1px' }}
          >
            {MoneyMask(financingPayments?.purchaseValueBiWeekly)}
          </Typography>
        )}
        <Typography variant="subtitle1" color={theme.colors.blackTitle}>
          {MoneyMask(isTradeInApplied ? financingPayments?.preTaxBiWeekly : financingPayments?.purchaseValueBiWeekly)}
        </Typography>
      </Stack>
    ) : (
      <Stack direction="row" spacing="12px" justifyContent="start">
        {isTradeInAvailable && isTradeInApplied && (
          <Typography
            variant="subtitle1"
            fontWeight={400}
            color={theme.colors.neutralDarkGrey}
            sx={{ textDecoration: 'line-through', textDecorationThickness: '1px' }}
          >
            {MoneyMask(clutchPlanSubtotal)}
          </Typography>
        )}

        <Typography variant="subtitle1" color={theme.colors.blackTitle}>
          {MoneyMask(isTradeInAvailable ? clutchPlanSubtotalWithTradeIn : clutchPlanSubtotal)}
        </Typography>
      </Stack>
    );
  };

  if (!clutchPlan.available) {
    return (
      <Typography variant="body1">
        <span className="bold">Not available for {isFinancing ? 'financing' : 'cash'}</span>
      </Typography>
    );
  }

  return (
    <Box>
      <Typography
        variant="h3"
        display="inline"
        sx={muiTheme => ({
          fontSize: isVehicleHold ? '24px' : '32px',
          // override the default font size for tablet and smaller
          [muiTheme.breakpoints.down('tablet')]: {
            fontSize: '32px',
            lineHeight: '40px',
          },
        })}
      >
        {isAnyPositiveEquity && isTradeInAvailable ? (
          <Stack spacing={2}>
            {getDisplayedPricing()}
            {showTradeInSavings && (
              <Box
                padding={1}
                borderRadius="6px"
                bgcolor={isTradeInAvailable ? theme.colors.lightSkyBlue : theme.colors.offWhite}
                border={`1px solid ${isTradeInAvailable ? theme.colors.teal : theme.colors.whisperGrey}`}
                textAlign="left"
              >
                <Typography variant="body2" color={isTradeInAvailable ? theme.colors.blackTitle : theme.colors.neutralDarkGrey}>
                  {isTradeInAvailable && isTradeInApplied
                    ? 'We took off ' + MoneyMask(tradeInCredit) + ' for your trade-in!'
                    : 'Trade-in is not available for this package'}
                </Typography>
              </Box>
            )}
          </Stack>
        ) : (
          <Stack spacing={2} alignItems="center">
            <Stack direction="row" justifyContent={{ base: 'start', sm: 'center' }} alignItems="baseline" gap="4px" width="100%">
              {getDisplayedPricing()}
              {isFinancing && (
                <>
                  <Typography variant="body1" color={theme.colors.blackTitle} display="inline">
                    /biweekly{' '}
                  </Typography>
                  <Typography variant="body1" display="inline" fontSize="12px">
                    (est.)*
                  </Typography>
                </>
              )}
            </Stack>
            {isTradeInApplied && showTradeInSavings && (
              <Box
                padding={1}
                borderRadius="6px"
                bgcolor={isTradeInAvailable ? theme.colors.lightSkyBlue : theme.colors.offWhite}
                border={`1px solid ${isTradeInAvailable ? theme.colors.teal : theme.colors.whisperGrey}`}
                textAlign="left"
              >
                <Typography variant="body2" color={isTradeInAvailable ? theme.colors.blackTitle : theme.colors.neutralDarkGrey}>
                  {isTradeInAvailable
                    ? 'You’re saving ' + MoneyMask(tradeInCredit) + ' with your trade-in!'
                    : 'Trade-in is not available for this package'}
                </Typography>
              </Box>
            )}
          </Stack>
        )}
      </Typography>
    </Box>
  );
};

export default PlanPrice;
