import { CITY_DETAILS } from '@clutch/common/constants/citySTC.data';

import { getSellMyCarForCityUrl } from 'src/components/PrivatePurchaseForm/steps/VehicleSearch/Landing/citySTC/citySTC';

type SellMyCarItem = {
  title: string;
  to: { pathname: string };
  highlightInFooter: boolean;
};

const sellMyCarData: SellMyCarItem[] = Object.values(CITY_DETAILS).map(cityDetail => ({
  title: `Sell My Car in ${cityDetail.name}`,
  to: { pathname: getSellMyCarForCityUrl(cityDetail.name) },
  highlightInFooter: cityDetail.highlightInFooter || false,
}));

export const sellMyCarFooterSection = {
  topItems: sellMyCarData.filter(item => item.highlightInFooter),
  remainingItems: sellMyCarData.filter(item => !item.highlightInFooter),
};
