import { constants as ClutchConstants } from '@clutch/clutch-common';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormControl, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ControllerContainer } from 'src/components/molecules/atoms/ControllerContainer';
import { TextInput, UnwrappedPostalCodeInput, WrappedStreetAddressInput } from 'src/components/molecules/atoms/Input';
import { countries } from 'src/constants';
import { wrapWithGoogleMaps } from 'src/contexts/GoogleMaps/GoogleMapsWrapper';
import { useKeyPressListener } from 'src/hooks';

import type { Address } from './types';
import { addressSchema, testAddress } from './utils';

const countryOptions = Object.values(countries).map(country => ({
  value: country,
  label: country,
}));
const provinceOptions: { value: string; label: string }[] = ClutchConstants.provinces.map((province: { label: string }) => ({
  value: province.label,
  label: province.label,
}));
const countryKeyAndValues = Object.entries(countries);

type AddressFormProps = {
  hideCountry?: boolean;
  defaultAddress?: Address;
  setAddress: (addressPayload: Address) => void;
  setIsFormValid?: (isValid: boolean) => void;
  autoComplete?: 'off';
};

const emptyAddress = {
  country: '',
  street: '',
  city: '',
  province: '',
  postalCode: '',
  raw: '',
  name: '',
  longitude: undefined,
  latitude: undefined,
  provinceCode: '',
};

export const AddressForm = wrapWithGoogleMaps(
  ({ hideCountry = false, defaultAddress, setAddress, setIsFormValid, autoComplete }: AddressFormProps) => {
    const {
      getValues,
      reset,
      control,
      setValue,
      formState: { isValid },
    } = useForm<Address>({
      resolver: zodResolver(addressSchema),
      mode: 'onBlur',
      reValidateMode: 'onChange',
      defaultValues: {
        country: defaultAddress?.country ?? 'Canada',
        street: defaultAddress?.street ?? '',
        apartment: defaultAddress?.apartment ?? undefined,
        city: defaultAddress?.city ?? '',
        province: defaultAddress?.province ?? '',
        postalCode: defaultAddress?.postalCode ?? undefined,
        provinceCode: defaultAddress?.provinceCode ?? undefined,
        raw: defaultAddress?.raw ?? undefined,
        longitude: defaultAddress?.longitude ?? undefined,
        latitude: defaultAddress?.latitude ?? undefined,
        name: defaultAddress?.name ?? undefined,
      },
    });

    const addressPayload = getValues();

    const isCanada = addressPayload.country === 'Canada';

    const setAddressToGoogleResult = ({ googleResult = emptyAddress }: { googleResult: Address }) => {
      reset({
        country: getValues().country,
        street: googleResult.street,
        city: googleResult.city,
        province: googleResult.province,
        postalCode: googleResult.postalCode,
        provinceCode: googleResult.provinceCode,
        raw: googleResult.raw,
        name: googleResult.name,
        longitude: googleResult.longitude,
        latitude: googleResult.latitude,
      });
    };

    useEffect(() => {
      setAddress(addressPayload);

      if (getValues('province')) {
        const provinceCode = ClutchConstants.provinces.find(
          (province: { value: string; label: string }) => getValues('province') === province.label,
        )?.value;
        if (provinceCode !== getValues('provinceCode')) {
          setValue('provinceCode', provinceCode || undefined);
        }
      }
    }, [...Object.values(addressPayload)]);

    useEffect(() => {
      if (setIsFormValid) setIsFormValid(isValid);
    }, [isValid]);

    // short key is called in address form instead of the parent component as react hook form caches
    // the default values on render and will not update if the value changes
    useKeyPressListener({ keys: ['Alt', 'ArrowLeft', 'ArrowRight'], onTrigger: () => reset(testAddress) });

    return (
      <Stack width={1} spacing={{ base: 2, xs: 3 }}>
        {!hideCountry && (
          <ControllerContainer label="Country">
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <FormControl>
                  <Select
                    {...field}
                    defaultValue={addressPayload.country}
                    onChange={(event: any) => reset({ ...emptyAddress, country: event.target.value })}
                  >
                    {countryOptions.map(({ value, label }) => (
                      <MenuItem value={value} key={value}>
                        <Typography variant="body1">{label}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </ControllerContainer>
        )}

        <WrappedStreetAddressInput
          label="Street address"
          control={control}
          name="street"
          countryCode={countryKeyAndValues.find(([, name]) => getValues().country === name)?.[0]}
          setFullAddress={(googleResult: Address) => setAddressToGoogleResult({ googleResult })}
        />

        <Stack width={1} direction={{ base: 'column', xs: 'row' }} spacing={2}>
          <TextInput label="Suite, unit (optional)" name="apartment" autoComplete={autoComplete} control={control} />
          <TextInput label="City" name="city" autoComplete={autoComplete} control={control} />
        </Stack>

        <Stack width={1} direction={{ base: 'column', xs: 'row' }} spacing={2}>
          <ControllerContainer label={isCanada ? 'Province' : 'Province/State'}>
            <Controller
              name="province"
              control={control}
              render={({ field }) =>
                isCanada ? (
                  <FormControl>
                    <Select {...field} autoComplete={autoComplete}>
                      {provinceOptions.map(({ value, label }) => (
                        <MenuItem value={value} key={value}>
                          <Typography variant="body1">{label}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField {...field} autoComplete={autoComplete} />
                )
              }
            />
          </ControllerContainer>
          <UnwrappedPostalCodeInput label="Postal code" name="postalCode" control={control} autoComplete={autoComplete} />
        </Stack>
      </Stack>
    );
  },
);
