import { FINANCING_YEAR_LOW_CUT_OFF, MINIMUM_LOAN_AMOUNT } from 'src/static';
import * as R from 'ramda';
import VehicleState from './vehicle-state';

export const isFinancingAllowed = ({
  isDealer,
  vehicle,
  totalLoanAmount,
}: {
  isDealer: boolean;
  vehicle: any;
  totalLoanAmount: number;
}) => {
  return (
    !isDealer &&
    R.path(['visibleOnSite'], vehicle) &&
    R.pathEq(['type'], 'LISTED', vehicle) &&
    !VehicleState.isFinalSale(vehicle.websiteState) &&
    vehicle.year >= FINANCING_YEAR_LOW_CUT_OFF &&
    totalLoanAmount >= MINIMUM_LOAN_AMOUNT
  );
};
