import styled from 'styled-components';
import { Stack } from '@mui/material';

export const VehicleCard = styled(Stack)<{ fullHeight?: boolean }>`
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  flex: 1 1 0%;
  width: 100%;
  height: auto;
  margin: 0;
  height: 100%;
  border-radius: 8px;
  border: 1px solid var(--Neutrals-Light-Grey, #ddd);
  background: var(--Neutrals-White, #fff);
  ${({ fullHeight }) =>
    fullHeight &&
    `
      min-height: 100%;
    `}
`;
