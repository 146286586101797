import { Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useFinanceCalculator } from 'src/hooks';
import { financing } from '@clutch/clutch-common/lib/utils';
import { isFinancingAllowed } from 'src/helpers';
import { AuthContext } from 'src/contexts';
import { FinancingModal } from './FinancingModal';

const { constants: FinancingConstants } = financing;

export const Financing = ({ vehicle }: { vehicle: any }) => {
  const { user } = useContext(AuthContext);
  const [openFinancingModal, setOpenFinancingModal] = useState(false);

  const financeCalculator =
    useFinanceCalculator({
      tradeInPriceInput: 0,
      tradeInTaxSavings: 0,
      isCheckout: false,
      isFinancing: true,
      interestRate: FinancingConstants.DEFAULT_VALUES.APR,
      vehicleDetails: vehicle,
    }) || {};

  const { purchaseValueBiWeekly, totalLoanAmount } = financeCalculator;
  const financingAllowed =
    !!purchaseValueBiWeekly &&
    isFinancingAllowed({
      isDealer: user?.isDealer,
      vehicle,
      totalLoanAmount,
    });

  return (
    <Stack height="18px">
      <FinancingModal
        open={openFinancingModal}
        vehicle={vehicle}
        financeCalculator={financeCalculator}
        onClose={e => {
          e.preventDefault();
          setOpenFinancingModal(false);
        }}
      />
      {financingAllowed && (
        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">or</Typography>
          <Typography
            onClick={e => {
              e.preventDefault();
              setOpenFinancingModal(true);
            }}
            variant="body2"
            sx={{ textDecoration: 'underline' }}
          >
            ${purchaseValueBiWeekly} /biweekly
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
