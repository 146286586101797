import { Divider, Stack, Typography } from '@mui/material';
import { theme } from 'src/theme';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';
import { getStaticPhotoUrl } from 'src/config';

const UberVoucherStack = styled(Stack)(() => ({
  display: 'flex',
  paddingTop: '16px',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'flex-start',
}));

const UberSectionStack = styled(Stack)(({ theme: muiTheme }) => ({
  display: 'flex',
  maxWidth: '540px',
  width: '100%',
  padding: '16px',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  borderRadius: '16px',
  border: '1px solid',
  borderColor: theme.colors.moonMistGrey,
  backgroundColor: theme.colors.white,
  marginTop: '32px',
  [muiTheme.breakpoints.down('xs')]: { marginTop: '16px' },
}));

const UberVoucherIcon = styled('img')<UberVoucherProps>(({ iconSize }) => ({
  width: iconSize,
}));

const defaultCopy: ReactNode = (
  <>
    You'll get your <strong style={{ color: theme.colors.blackTitle }}>$50 voucher</strong> for your ride home after the sale is complete!
  </>
);

const UberVoucherInner = ({ children, iconSize }: { children: ReactNode; iconSize?: string }) => {
  return (
    <>
      <UberVoucherIcon iconSize={iconSize} src={getStaticPhotoUrl('uberLogo.svg')} alt="Uber" />
      <Typography
        color={theme.colors.blackTitle}
        variant="body2"
        sx={muiTheme => ({ [muiTheme.breakpoints.down('xs')]: { fontSize: '14px', lineHeight: '18px' } })}
      >
        {children}
      </Typography>
    </>
  );
};

type UberVoucherProps = {
  paddingTop?: string;
  children?: ReactNode;
  divider?: boolean;
  iconSize?: string;
};

export const UberVoucherFooter = ({ paddingTop = '0', children = defaultCopy, divider = true, iconSize = '48px' }: UberVoucherProps) => (
  <Stack width={1} paddingTop={paddingTop}>
    {divider && <Divider />}
    <UberVoucherStack direction="row" spacing={divider ? 2 : 1} sx={{ ...(!divider && { paddingTop: 0 }) }}>
      <UberVoucherInner iconSize={iconSize}>{children}</UberVoucherInner>
    </UberVoucherStack>
  </Stack>
);

export const UberVoucherSection = () => (
  <UberSectionStack direction="row" spacing={1.5}>
    <UberVoucherInner>
      Dropping off your car at Clutch? We’ll give you a <strong style={{ color: theme.colors.blackTitle }}>$50 voucher</strong> for your
      ride home!
    </UberVoucherInner>
  </UberSectionStack>
);
