export const formatNumbers = n => {
  if (n) {
    return n.toLocaleString('en-CA');
  }

  return '';
};

export const formatDollars = (n, dropCents = true) => {
  // NOTE: There is a 'currency' style which would usually do what we want, but
  // which sometimes would show currency symbols in a way we want to avoid.
  // Make sure not to use this.
  const options = {
    style: 'decimal',
    useGrouping: true,
  };

  if (dropCents) {
    options.minimumFractionDigits = 0;
    options.maximumFractionDigits = 0;
  } else {
    options.minimumFractionDigits = 2;
    options.maximumFractionDigits = 2;
  }

  return `$${(n || 0.0).toLocaleString('en-CA', options)}`;
};

export const formatThousands = n => (n < 1000 ? n.toString() : `${Math.round(formatNumbers(n / 1000))}K`);

export const formatMileage = n => (n < 4000 ? formatNumbers(n) : formatThousands(n));

export const getMailtoUrl = (to, subject, body) => {
  const args = [];
  if (typeof subject !== 'undefined') {
    args.push(`subject=${encodeURIComponent(subject)}`);
  }
  if (typeof body !== 'undefined') {
    args.push(`body=${encodeURIComponent(body)}`);
  }

  let url = `mailto:${encodeURIComponent(to)}`;
  if (args.length > 0) {
    url += `?${args.join('&')}`;
  }
  return url;
};

export const distinct = array => array.filter((value, index, self) => self.indexOf(value) === index);

export function areStringArraysEqual(a, b) {
  if (!!a !== !!b) {
    return false;
  }

  if (!a) {
    return true;
  }

  if (a.length !== b.length) {
    return false;
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }

  return true;
}

export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
