import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { theme } from '../../../../../theme';

const Container = styled(Box)(() => ({
  padding: '4px 8px',
  backgroundColor: theme.colors.offWhite,
}));

export const CarStateRibbon = ({ text }: { text: string }) => {
  return (
    <Container>
      <Typography color={theme.colors.blackTitle} variant="body2" fontWeight={500}>
        {text}
      </Typography>
    </Container>
  );
};
