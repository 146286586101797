import * as R from 'ramda';
import { formatDollars } from '../../../../../../../helpers/utils';

const getVehicleSellingPrice = vehicle => {
  const amountDiscounted = R.path(['amountDiscounted'], vehicle);
  const percentDiscounted = R.path(['percentDiscounted'], vehicle);

  let vehiclePrice = vehicle.price;
  if (amountDiscounted) {
    vehiclePrice = vehicle.price - amountDiscounted;
  } else if (percentDiscounted) {
    vehiclePrice = vehicle.price - (vehicle.price * percentDiscounted);
  }

  return formatDollars(vehiclePrice).replace(/\$/g, '');
};

export default getVehicleSellingPrice;
