import { DELIVERY_COORDINATION_MESSAGE } from '@clutch/common/constants/orders';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useContext } from 'react';

import { CheckoutContext } from 'src/contexts';

import * as Styled from '../OrderDetails.styles';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

export const StyledValue = styled(Typography)({
  width: '50%',
  textAlign: 'right',
});

export const Pickup = () => {
  const { answers: checkout } = useContext(CheckoutContext);
  const { address, scheduledAt, location } = checkout?.deliveryDetails || {};
  const { region } = location || {};
  const { provinceId } = region || {};
  const { street, city, provinceCode, province, postalCode } = address || {};

  const displayProvince = provinceCode || provinceId || province;

  const formattedDate =
    scheduledAt &&
    dayjs.tz(`${scheduledAt.date}T${scheduledAt.time}`, location?.timezone || 'America/Toronto').format('dddd, MMMM D [at] h:mm a z');

  if (!street || !city || !displayProvince || !postalCode) return null;

  return (
    <>
      <Styled.BreakdownHeaderRow sx={{ marginBottom: '24px' }}>
        <Styled.BreakdownHeader>Location</Styled.BreakdownHeader>
        <StyledValue variant="body2">
          {street}, {city}, {displayProvince}, {postalCode}
        </StyledValue>
      </Styled.BreakdownHeaderRow>
      <Styled.BreakdownHeaderRow>
        <Styled.BreakdownHeader>Date</Styled.BreakdownHeader>
        <StyledValue variant="body2">{formattedDate || DELIVERY_COORDINATION_MESSAGE}</StyledValue>
      </Styled.BreakdownHeaderRow>
    </>
  );
};
