import { formatMileage } from '@clutch/helpers';

import type { Vehicle } from 'src/types/api';

type HelmetData = {
  title: string | ((city: string, provinceCode: string) => string) | ((param: string) => string) | ((vehicle: Vehicle) => string);
  description: string | ((param: string) => string) | ((vehicle: Vehicle) => string);
};

type HelmetDataObject = Record<string, HelmetData>;

const vehicleDescription = (vehicle: Vehicle): string => {
  if (vehicle.name) {
    return `View this used ${vehicle.year} ${vehicle.name} with ${formatMileage(vehicle.mileage)} km | Clutch`;
  }
  return 'Your next car. We give you 10 days to see if your Clutch car is perfect for you. | Clutch';
};

const HELMET_DATA: HelmetDataObject = {
  DEFAULT: {
    title: 'The Best Way To Buy A Car | Skip The Dealership | Clutch',
    description: 'Browse online and have your car delivered without going to a dealership. All cars come with a 10-day return guarantee.',
  },
  MY_DASHBOARD: {
    title: 'My Dashboard | Clutch',
    description: 'View purchases and sales associated with your account in My Dashboard.',
  },
  LANDING_PAGE: {
    title: 'Buy and Sell Used Cars Online | Clutch',
    description:
      'Buy your next car online with Clutch — Canada’s largest online used car retailer. Browse hundreds of vehicles, secure financing, and enjoy doorstep delivery.',
  },
  // see Vehicle Grid Context for dynamic title and description
  SHOWROOM: {
    title: 'Used Cars for Sale | Clutch',
    description:
      'Shop hundreds of certified used cars for sale on Clutch. Get approved for financing quickly, buy online, and have it delivered to your door.',
  },
  SHOWROOM_CITY: {
    title: (city: string, provinceCode: string) => `Used Cars for Sale in ${city}, ${provinceCode} | Clutch`,
    description: (city: string) =>
      `Shop hundreds of certified used car for sale in ${city}. Get approved for financing quickly, buy online, and have it delivered to your door.`,
  },
  SHOWROOM_FILTERS: {
    title: (filters: string, city?: string, provinceCode?: string) =>
      `Used ${filters} for Sale${city && provinceCode ? ` in ${city}, ${provinceCode}` : ''} | Clutch`,
    description: (filters: string, city?: string) =>
      `Shop used ${filters} for sale online${
        city ? ` in ${city}` : ''
      }. View CARFAX and 210-point inspection reports for each car. Buy your next car online with confidence on Clutch.`,
  },
  VEHICLE_DETAILS: {
    title: (vehicle: Vehicle) => `${vehicle.name ? `${vehicle.year} ${vehicle.name}` : 'Vehicle Details'} | Clutch`,
    description: (vehicle: Vehicle) => vehicleDescription(vehicle),
  },
  CHECKOUT: {
    title: (vehicle: Vehicle) => `Purchase ${vehicle.name ? `${vehicle.year} ${vehicle.name}` : 'your vehicle'} | Clutch | ${vehicle.id}`,
    description: (vehicle: Vehicle) =>
      `Purchase ${vehicle.name ? `${vehicle.year} ${vehicle.name}` : 'your vehicle'} and have it delivered to your doorstep.`,
  },
  RETAIL_CHECKOUT: {
    title: (vehicle: Vehicle) => `Purchase ${vehicle.name ? `${vehicle.year} ${vehicle.name}` : 'your vehicle'} | Clutch | ${vehicle.id}`,
    description: (vehicle: Vehicle) =>
      `Purchase ${vehicle.name ? `${vehicle.year} ${vehicle.name}` : 'your vehicle'} and have it delivered to your doorstep.`,
  },
  DEALER_CHECKOUT: {
    title: (vehicle: Vehicle) =>
      `Dealer | Purchase ${vehicle.name ? `${vehicle.year} ${vehicle.name}` : 'your vehicle'} | Clutch | ${vehicle.id}`,
    description: (vehicle: Vehicle) =>
      `Dealer | Purchase ${vehicle.name ? `${vehicle.year} ${vehicle.name}` : 'your vehicle'} and have it delivered to your doorstep.`,
  },
  FINANCE_APPLICATION: {
    title: 'Financing Application | Clutch',
    description: 'Apply for car financing online to find out your loan eligibility.',
  },
  INSPECTION_REPORT_FULL: {
    title: 'Inspection Report | Clutch',
    description:
      'Every car is certified with a 210-point Clutch inspection, complete with a 90-day / 6,000 km warranty when you purchase online.',
  },
  PRIVATE_PURCHASE: {
    title: 'Sell My Car | Get a firm offer online in 2 minutes | Clutch',
    description:
      'Sell your car online quickly and easily with Clutch. Enter your licence plate or VIN to get a real, custom offer. Good for 7 days!',
  },
  SELL_MY_CAR_CITY: {
    title: (city: string) => `Sell My Car in ${city} | Get a Firm Offer Online in 2 Minutes | Clutch`,
    description: (city: string) =>
      `Sell your car online quickly and easily in ${city}. Enter your licence plate or VIN to get a real, custom offer. Good for 7 days!`,
  },
  PRIVATE_PURCHASE_OFFER: {
    title: 'Your Offer | Clutch',
    description: 'Here’s your offer! Please note that this offer may be subject to modification as we verify the vehicle’s information.',
  },
  PRIVATE_PURCHASE_RARE_FIND: {
    title: 'Rare Find | Clutch',
    description:
      'Due to a lack of recent data on you car, we were unable to generate an instant offer on your vehicle. Please contact us so we can manually review your vehicle and provide an offer.',
  },
  PRIVATE_PURCHASE_EDIT: {
    title: 'Sell or Trade Your Car | Clutch',
    description:
      "Sell or Trade your car with Clutch. Get a cash offer for your vehicle by entering your VIN number. Don't know your VIN? Complete the form to find out your car's value.",
  },
  FINANCING: {
    title: 'Car Loans and Auto Financing | Pre-Qualify Today | Clutch',
    description:
      'Auto financing with Clutch is quick and easy. Get pre-qualified for financing in minutes with our transparent terms and no impact to your credit score.',
  },
  PROTECTION_PLANS: {
    title: 'Protection Plans | Clutch',
    description:
      'Clutch’s comprehensive auto protection plans have you covered. Get peace of mind with our variety of coverage options including extended warranties, GAP insurance, and tire protection.',
  },
  ABOUT: {
    title: 'About | Clutch',
    description:
      'Bringing Canadians high-quality vehicles, with the convenience of buying online and peace of mind knowing your car is certified with a 7day return policy.',
  },
  CONTACT: {
    title: 'Contact Us | Clutch',
    description: 'The Clutch team are ready to assist you in the car buying process online, over the phone or in person. Contact us now.',
  },
  FAQ: {
    title: 'FAQ | Clutch',
    description: 'Have a question about buying your car? The Clutch team is happy to help!',
  },
  PRIVACY_POLICY: {
    title: 'Privacy Policy | Clutch',
    description: 'The Clutch Privacy Policy.',
  },
  TEN_DAY_MONEY_BACK_GUARANTEE: {
    title: 'Ten Day Money Back Guarantee | Clutch',
    description: 'The ten day money back guarantee.',
  },
  NINETY_DAY_PROTECTION_PLAN: {
    title: 'Ninety Day Protection Plan | Clutch',
    description: 'Ninety day protection plan.',
  },
  TERMS_OF_SERVICE: {
    title: 'Terms of Service | Clutch',
    description: 'The Clutch Terms of Service.',
  },
  VENDOR_TERMS_AND_CONDITIONS: {
    title: 'Vendor Terms and Conditions | Clutch',
    description: 'The Vendor Terms and Conditions.',
  },
  REFERRAL_TERMS_AND_CONDITIONS: {
    title: 'Referral Terms and Conditions | Clutch',
    description: 'The Referral Terms and Conditions.',
  },
  PAGE_NOT_FOUND: {
    title: (location: string) => `Page Not Found | Clutch | ${location.slice(1)}`,
    description: 'The page you are looking for does not exist.',
  },
  CAREERS: {
    title: 'Careers | Clutch',
    description: 'Looking for an opportunity to achieve something incredible? Come work for us!',
  },
  APPLY_TO_JOB: {
    title: 'Apply | Clutch',
    description: 'Looking for an opportunity to achieve something incredible? Come work for us!',
  },
  JOB_DETAILS: {
    title: 'Apply | Clutch',
    description: 'Looking for an opportunity to achieve something incredible? Come work for us!',
  },
  WARRANTY: {
    title: '90-Day Protection Plan | Clutch',
    description: 'Every vehicle purchase from Clutch comes with a limited guarantee of up to 90 days or 6,000 km, whichever comes first.',
  },
  PRE_QUALIFY: {
    title: 'Pre-Qualify | Clutch',
    description: 'Get Pre-Qualified with no impact to your credit score!',
  },
  ORDER_CLIENT_RESCHEDULE: {
    title: 'Reschedule | Clutch',
    description: 'Reschedule delivery for order',
  },
  SELL_TO_CLUTCH_TOS: {
    title: 'Sell or Trade Terms and Conditions | Clutch',
    description: 'Sell or Trade Terms and Conditions',
  },
  ACCESSIBILITY: {
    title: 'Accessibility Policy and Plan | Clutch',
    description: 'Accessibility Policy and Plan',
  },
  CLUTCH_SHOT_RULES: {
    title: 'Clutch Shot Contest | Clutch',
    description: 'Clutch Shot Contest',
  },
  AUTO_INSURANCE_CONTEST_TC: {
    title: 'Auto Insurance Contest | Clutch',
    description: 'Auto Insurance Contest',
  },
  AMAZON_GIFT_CARD_RULES: {
    title: '$1000 Amazon Gift Card Holiday Contest | Clutch',
    description: '$1000 Amazon Gift Card Holiday Contest',
  },
  LOAN_CALCULATOR: {
    title: 'Car Loan Calculator | Calculate Monthly Auto Loan Payments',
    description:
      'Calculate car loan payments easily with our Car Loan Calculator. Get accurate estimates for monthly payments, interest rates, and loan terms for your next car.',
  },
};

export default HELMET_DATA;
