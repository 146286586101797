import { ROUTES } from '../static';
const scrollTopState = { scrollToTop: true };

export const footerCopyrightText = `© ${new Date().getFullYear()} Clutch Technologies Inc. All Rights Reserved.`;

export const headerNavigation = [
  {
    primary: true,
    key: 'header-link_browse-inventory',
    label: 'Shop cars',
    to: ROUTES.SHOWROOM[0],
  },
  {
    primary: true,
    key: 'header-link_about',
    label: 'About',
    to: ROUTES.ABOUT,
  },
  {
    primary: true,
    key: 'header-link_sell-or-trade',
    label: 'Sell or Trade Your Car',
    to: ROUTES.PRIVATE_PURCHASE,
  },
  {
    key: 'header-link_secure-financing',
    label: 'Financing',
    to: ROUTES.AUTO_FINANCING.ROOT,
  },
  {
    key: 'header-link_protection-plans',
    label: 'Protection Plans',
    to: ROUTES.PROTECTION_PLANS,
  },
];

type Location = {
  pathname: string;
  hash?: string;
  state?: Record<string, boolean>;
};

type NavigationData = {
  title: string;
  data: {
    title: string;
    to: Location;
    isExternal?: boolean;
  }[];
};

export const footerNavigationMap: Record<string, NavigationData> = {
  explore: {
    title: 'Explore',
    data: [
      {
        title: 'Home',
        to: { pathname: ROUTES.LANDING_PAGE[0] },
      },
      {
        title: 'Shop cars',
        to: {
          pathname: ROUTES.SHOWROOM[0],
          state: {
            browseInventory: true,
            resetFilters: true,
          },
        },
      },
      {
        title: 'Sell or Trade',
        to: { pathname: ROUTES.PRIVATE_PURCHASE },
      },
      {
        title: 'Finance with Clutch',
        to: {
          pathname: ROUTES.AUTO_FINANCING.ROOT,
          state: scrollTopState,
        },
      },
      {
        title: 'Vehicle Protection',
        to: {
          pathname: ROUTES.PROTECTION_PLANS,
          state: scrollTopState,
        },
      },
    ],
  },
  company: {
    title: 'Company',
    data: [
      {
        title: 'About Clutch',
        to: {
          pathname: ROUTES.ABOUT,
          state: scrollTopState,
        },
      },
      {
        title: 'Careers',
        to: {
          pathname: ROUTES.CAREERS,
          state: scrollTopState,
        },
      },
      {
        title: 'Blog',
        to: { pathname: 'https://blog.clutch.ca' },
        isExternal: true,
      },
      {
        title: 'FAQ',
        to: {
          pathname: ROUTES.FAQ,
          state: scrollTopState,
        },
      },
    ],
  },
};

export default {};
