// Load WAF environment at start
export async function loadWAFEnv (envFile = './wafenv.json') {
  window.AWS_WAF_ENV = {
    JSAPI_URL: 'https://80a7fd0a5ce9.ca-central-1.captcha-sdk.awswaf.com/80a7fd0a5ce9/jsapi.js',
    CAPTCHA_API_KEY: 'N1hHs3a/BxWv7vLuRtefp2gyF+QxyGN8q/J51DMcCJfepZLXvhmA9LbbtK4l2GsvaZ/z+iFjFkmM6epysCdwfXeD1B+YBVjk9LvOJX9FOq2KqhlOCdCmfNvUOW5N1MYzikM8dwW3SQRNDJJUiHrkO6QHCNjfv5QjlXSVoPKNx8iqK6HzOmcUm43pweDaqKBNVYx7Njf7XgP4c5aSoXhJ1q33JKNmqDu0LOrB7Q0g3VimoYJ50/rdyn7tcC3/LfI2TL+lPv/Pn6w7ehjMT993KpmMF89ckJXdSI3MGTZu3ihmPKU1qbN1VdTq5wYsRuWAnG+i83M/sdFvPlBZARyGa0vHJk7B7xEIBlwdzY3VlTOecqdhR/OOn/tvqNGJbWoKg305EYLr/oFzxS7UYrlSAGyiqh6xasAV1guQ4VoMK5jRHCv+PlfDQNXRKbYyAmQWIsNdzkKceIhlyayG0hXRgk3eCjNs5Vesfcfzi+mhraCq8xyAy1Jf0e5S4U1/0UeomGeY78LA11SjYv1xgdQVpToekUrF0iawJzeouuoKSdYkMuTZXkOxoprv4M7c3x/ypsUeKaAkhsVirmhnv37pPsol9cQmD1bPXPWU5ir7HObuQmLXskCRNXS0uUioULGZ+oKKRnMyyjs0zG0WGhqb33O5fu4C8NkwB7+IDrvpmP0=_0_1',
  };
}

export function getWAFEnv () {
  return window.AWS_WAF_ENV;
}

// Loads the AWS WAF JS API Script dynamically
export function loadScript () {
  if (document.getElementById('AwsWAFScript')) return;

  const AwsWafScript = document.createElement('script');
  AwsWafScript.id = 'AwsWAFScript';
  AwsWafScript.async = false;
  AwsWafScript.src = getWAFEnv().JSAPI_URL;
  document.head.appendChild(AwsWafScript);
}