import { useBooleanState } from '@clutch/hooks';
import { ClickAwayListener } from '@mui/material';

import { InfoIcon } from './InfoIcon';
import * as Styled from './Tooltip.styles';

export type TooltipProps = {
  title: JSX.Element | string;
  children?: JSX.Element;
  leaveDelay?: number;
  noWrapperPadding?: boolean;
  open?: boolean;
};
export const Tooltip = ({ title, children, ...rest }: TooltipProps) => {
  const tooltipOpenState = useBooleanState({ initialState: false });

  return (
    <Styled.TooltipWrapper {...rest}>
      <ClickAwayListener onClickAway={tooltipOpenState.setFalse}>
        <div onClick={tooltipOpenState.setTrue}>
          <Styled.Tooltip
            title={title}
            open={tooltipOpenState.value}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            {...rest}
          >
            <div style={{ cursor: 'pointer' }}>{children || <InfoIcon />}</div>
          </Styled.Tooltip>
        </div>
      </ClickAwayListener>
    </Styled.TooltipWrapper>
  );
};
