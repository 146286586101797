import { useBooleanState } from '@clutch/hooks';
import { InputAdornment } from '@mui/material';
import type { ChangeEventHandler, KeyboardEvent } from 'react';
import { useState } from 'react';

import * as SharedStyle from '../FormInput.styles';
import * as Styled from './TextInput.styles';

type TextInputProps = {
  label?: React.ReactNode;
  value: string | undefined;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  isMultiline?: boolean;
  disabled?: boolean;
  error?: boolean;
  required?: boolean;
  optionalInput?: boolean;
  optionalInputText?: string;
  errorMessage?: string;
  disableErrorOnFocus?: boolean;
  readOnly?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  type?: string;
  isFocused?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  autoComplete?: string;
  maskingFunction?: (value: string) => string;
  showErrorMessage?: boolean;
  maxLength?: number;
  inputRef?: any;
  onKeyDown?: (event: KeyboardEvent) => void;
  startAdornment?: string;
  name?: string;
  minHeight?: string;
};

export const TextInput = ({
  label,
  value,
  onChange,
  isMultiline = false,
  disabled = false,
  error = false,
  required = false,
  optionalInput = false,
  optionalInputText = 'Add optional input',
  errorMessage = 'Please enter a valid value',
  disableErrorOnFocus = false,
  readOnly = false,
  placeholder = '',
  type = 'text',
  isFocused = true,
  fullWidth = false,
  onFocus,
  onBlur,
  autoComplete,
  maskingFunction = value => value,
  showErrorMessage = true,
  maxLength,
  inputRef,
  onKeyDown,
  startAdornment,
  name,
  minHeight,
  ...rest
}: TextInputProps) => {
  const [showOptionalInputState, setShowOptionalInputState] = useState(!!value);
  const isFocusedState = useBooleanState();

  const hideErrorState = disableErrorOnFocus && isFocusedState.value;

  const checkErrorMessage = !hideErrorState && error && !readOnly;

  return optionalInput && !showOptionalInputState ? (
    <Styled.OptionalInput onClick={() => setShowOptionalInputState(true)}>
      <Styled.IconContainer>
        <Styled.AddIcon />
      </Styled.IconContainer>
      <Styled.OptionalText>{optionalInputText}</Styled.OptionalText>
    </Styled.OptionalInput>
  ) : (
    <SharedStyle.FormContainer
      className="text-input-form-container"
      label={label}
      showErrorMessage={showErrorMessage}
      fullWidth={fullWidth}
      minHeight={minHeight}
    >
      {label && <SharedStyle.Label>{label}</SharedStyle.Label>}
      {optionalInput && showOptionalInputState && (
        <Styled.RemoveInput
          onClick={() => {
            setShowOptionalInputState(false);
          }}
        >
          <Styled.IconContainer>
            <Styled.RemoveIcon />
          </Styled.IconContainer>
          <Styled.OptionalText>Remove</Styled.OptionalText>
        </Styled.RemoveInput>
      )}
      <Styled.TextField
        inputRef={inputRef}
        value={maskingFunction(value || '')}
        onChange={(event: any) => {
          if (maxLength === undefined || event.target.value.length <= maxLength) onChange(event);
        }}
        required={required}
        multiline={isMultiline}
        disabled={disabled}
        error={checkErrorMessage}
        placeholder={placeholder}
        onFocus={() => {
          if (onFocus) onFocus();
          isFocusedState.setTrue();
        }}
        onBlur={() => {
          if (onBlur) onBlur();
          isFocusedState.setFalse();
        }}
        isFocused={isFocused && isFocusedState.value}
        readOnly={readOnly}
        type={type}
        autoComplete={autoComplete}
        onKeyDown={onKeyDown}
        startAdornment={startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>}
        fullWidth={fullWidth}
        name={name}
        {...rest}
      />
      {checkErrorMessage && showErrorMessage && (
        <SharedStyle.ErrorContainer>
          <SharedStyle.ErrorSymbol />
          <SharedStyle.ErrorText>{errorMessage}</SharedStyle.ErrorText>
        </SharedStyle.ErrorContainer>
      )}
    </SharedStyle.FormContainer>
  );
};
