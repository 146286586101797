import ClutchApiWrapper from './client/clutchAPIWrapper';
import { useAWSWAFCaptchaAxios } from "src/wafCaptcha";
import axios from "axios";

const captchaAxios = useAWSWAFCaptchaAxios();

const signUp = ({ payload }) =>
  ClutchApiWrapper.api.public.post(`/accounts/signUp`, payload);

const signIn = ({ payload }) =>
  ClutchApiWrapper.api.public.post(`/accounts/signIn`, payload);

const google = ({ payload }) =>
  ClutchApiWrapper.api.public.post(`/accounts/google`, payload);

const facebookSignIn = ({ payload }) =>
  ClutchApiWrapper.api.public.post(`/accounts/facebook/signIn`, payload);

const facebookSignUp = ({ payload }) =>
  ClutchApiWrapper.api.public.post(`/accounts/facebook/signUp`, payload);

const emailExists = ({ payload }) =>
  captchaAxios.post(`https://api.datsun.clutchenv.ca/v1/accounts/userExists`, payload);

const signOut = () =>
  ClutchApiWrapper.api.authenticated.post(`accounts/signOut`);

const sendForgotPasswordEmail = ({ payload }) =>
  ClutchApiWrapper.api.public.post(`accounts/forgotPassword`, payload);

const submitForgotPassword = ({ payload }) =>
  ClutchApiWrapper.api.public.post(`accounts/forgotPassword/confirm`, payload);

const sendEmailVerification = () =>
  ClutchApiWrapper.api.authenticated.post('/accounts/send/emailVerification');

const verifyEmail = ({ token, id }) =>
  ClutchApiWrapper.api.public.post('/accounts/verify/email', { token, id });

const updateSapEmail = ({ email }) =>
  ClutchApiWrapper.api.authenticated.patch('/accounts/sap/email', { email });

export default {
  signUp,
  signIn,
  signOut,
  emailExists,
  google,
  facebookSignIn,
  facebookSignUp,
  sendForgotPasswordEmail,
  submitForgotPassword,
  sendEmailVerification,
  verifyEmail,
  updateSapEmail,
};
