import { useBooleanState } from '@clutch/hooks';
import { Box, Link, Typography } from '@mui/material';

import { CallSupport } from 'src/components/CallSupport';
import { theme } from 'src/theme';

const ScheduleCallSupport = () => {
  const preceedingText = "Can't find a time that works?";
  const callSupportState = useBooleanState({ initialState: false });

  return (
    <Box>
      <Typography variant="body2" textAlign={{ base: 'center', md: 'start' }}>
        {preceedingText}
        <Link
          marginLeft="4px"
          sx={{
            textDecoration: 'underline',
          }}
          color={theme.colors.blackTitle}
          fontSize="14px"
          fontWeight="400"
          onClick={callSupportState.setTrue}
        >
          Contact us
        </Link>
      </Typography>
      <CallSupport open={callSupportState.value} onClose={callSupportState.setFalse} />
    </Box>
  );
};

export default ScheduleCallSupport;
