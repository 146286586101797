import { constants as ClutchConstants } from '@clutch/clutch-common';
import { Stack } from '@mui/material';
import { CarStateRibbon, Sale, ShippingFee } from './Ribbons';
import * as R from 'ramda';
import { AuthContext } from 'src/contexts';
import { useContext } from 'react';

type VehicleRibbonProps = {
  vehicle: any;
  isSale: boolean;
  showShippingFee: boolean;
};

const carStateText = {
  [ClutchConstants.vehicleStates.SALE_PENDING]: 'Reserved',
  [ClutchConstants.vehicleStates.COMING_SOON]: 'Coming soon',
};

export const VehicleRibbons = ({ showShippingFee, vehicle, isSale }: VehicleRibbonProps) => {
  const shippingFee = R.path(['shippingFee'], vehicle);
  const { user } = useContext(AuthContext);

  const carState = carStateText[vehicle.websiteState];

  if (carState) {
    return (
      <Stack direction="row" spacing={1} zIndex={100} position="absolute" top="8px" left="8px">
        <CarStateRibbon text={carState} />
      </Stack>
    );
  }
  return (
    <Stack direction="row" spacing={1} zIndex={100} position="absolute" top="8px" left="8px">
      {isSale && <Sale />}
      {!user?.isDealer && showShippingFee && <ShippingFee fee={shippingFee} />}
    </Stack>
  );
};
