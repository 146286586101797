import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { theme } from '../../../../../theme';

const ShippingFeeContainer = styled(Box)(() => ({
  padding: '4px 8px',
  backgroundColor: theme.colors.offWhite,
  border: `1px solid ${theme.colors.moonMistGrey}`,
}));
export const ShippingFee = ({ fee }: { fee: number }) => {
  return fee > 0 ? (
    <ShippingFeeContainer>
      <Typography variant="body2" fontWeight={500}>
        ${fee} shipping
      </Typography>
    </ShippingFeeContainer>
  ) : (
    <></>
  );
};
