import * as Sentry from '@sentry/browser';
import { useTrace } from 'src/hooks';
import { useToast } from 'src/stores';
import useSWR from 'swr/immutable';
import useSWRImmutable from 'swr/immutable';

import { swrFetcher } from './utils/swrFetcher';

export const useClutchPlans = (checkoutId: string) => {
  const toast = useToast();

  return useSWR(checkoutId ? `/retail/checkouts/${checkoutId}/clutchPlans` : null, swrFetcher, {
    onError: error => {
      toast.open({ type: 'error', message: 'Failed to get your payment plans. Please refresh to try again.' });
      Sentry.captureException(error);
      Sentry.captureMessage('Unable to fetch Clutch Packages');
    },
  });
};

export const useProtections = (checkoutId: string) => {
  return useSWR(checkoutId ? `/retail/checkouts/${checkoutId}/protections` : null, swrFetcher, {
    onError: error => {
      Sentry.captureMessage(`Failed to get all protections: ${JSON.stringify(error)}`);
      Sentry.captureException(error);
    },
  });
};

export const useWarrantyAncillaries = (checkoutId: string) => {
  return useSWR(checkoutId ? `/retail/checkouts/${checkoutId}/warrantyAncillaries` : null, swrFetcher, {
    onError: error => {
      Sentry.captureMessage(`Failed to get ancillary protections: ${JSON.stringify(error)}`);
      Sentry.captureException(error);
    },
  });
};

export const useReceptionTypes = (checkoutId: string, deliveryAddress: Record<string, string>) => {
  const trace = useTrace();

  return useSWRImmutable(checkoutId && deliveryAddress ? `/retail/checkouts/${checkoutId}/reception/types` : null, swrFetcher, {
    onError: error => {
      trace.report({
        type: 'error',
        actionName: 'Cannot find checkout reception types',
        data: error,
      });
    },
  });
};
