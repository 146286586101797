import { Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { CarDetails, CashPrice, Financing } from './components';
import { styled } from '@mui/material/styles';
import { AuthContext } from 'src/contexts';
import { SubSectionDivider } from 'src/components/molecules/atoms/Dividers';

const VehicleInformationContainer = styled(Stack)`
  justify-content: center;
  width: 100%;
  padding: 12px;
`;

export type VehicleInformationProps = {
  vehicle: any;
  previousPrice?: number | null;
  isSale: boolean;
};

export const VehicleInformation = (args: VehicleInformationProps) => {
  const { vehicle } = args;
  const { user } = useContext(AuthContext);

  return (
    <VehicleInformationContainer spacing={1.5}>
      <CarDetails vehicle={vehicle} />
      <SubSectionDivider />
      <CashPrice {...args} />
      <Financing vehicle={vehicle} />
      <Typography variant="caption">+ HST & {user?.isDealer ? 'Dealer Fees' : 'Licensing'}</Typography>
    </VehicleInformationContainer>
  );
};
