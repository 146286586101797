import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { theme } from 'src/theme';

dayjs.extend(customParseFormat);

const TimeItem = ({
  timeString,
  selected,
  handleTimeClick,
}: {
  timeString: string;
  selected: boolean;
  handleTimeClick: (time: string) => void;
}) => {
  const getTime = (timeString: string): string => {
    const time = dayjs(timeString, 'HH:mm:ss');

    return time.format('h:mm a');
  };

  return (
    <Box
      paddingX={2}
      paddingY={{ base: 1, md: 2 }}
      borderRadius="1px"
      margin="4px"
      sx={{
        cursor: 'pointer',
        outline: selected ? '2px solid' : '1px solid',
        outlineColor: selected ? theme.colors.blackTitle : theme.colors.moonMistGrey,
      }}
      textAlign="center"
      whiteSpace="nowrap"
      width={{ base: 'fit-content', md: '100%' }}
      onClick={() => handleTimeClick(timeString)}
    >
      <Typography fontWeight="400" color={theme.colors.blackTitle}>
        {getTime(timeString)}
      </Typography>
    </Box>
  );
};

export default TimeItem;
