import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled from 'styled-components';

type ComponentProps = {
  noWrapperPadding?: boolean;
  zIndex?: number;
  onClick?: Function;
};

export const TooltipWrapper = styled.div<ComponentProps>`
  ${({ noWrapperPadding }) => !noWrapperPadding && 'padding: 1px 0 1px 10px'};
  ${({ zIndex }) => zIndex && `z-Index: ${zIndex};`}
  display: inline;
`;

export const Tooltip = styled(props => (
  <MuiTooltip
    arrow
    placement="top-start"
    classes={{
      popper: props.className,
      tooltip: 'tooltip',
    }}
    PopperProps={{
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top-end'],
          },
        },
      ],
    }}
    {...props}
  />
))`
  && {
    vertical-align: sub;
    .${tooltipClasses.tooltip} {
      pointer-events: auto;
      font-family: 'Circular';
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: left;
      padding: 16px;
      ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
      background: ${({ theme }) => theme.getColor('@clutch/darkText')};
      .${tooltipClasses.arrow} {
        color: transparent !important;
        transform: none !important;
        width: 12px;
        height: 12px;
      }
      .${tooltipClasses.arrow}:after {
        display: block;
        content: '';
        position: absolute;
        top: -4px;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
      }
      .${tooltipClasses.arrow}:before {
        display: none;
      }
    }
    &[data-popper-placement*='top-start'] .${tooltipClasses.tooltip} {
      border-radius: 16px 16px 16px 0px;
      .${tooltipClasses.arrow}:after {
        border-left: 12px solid ${({ theme }) => theme.getColor('@clutch/darkText')};
      }
    }
    &[data-popper-placement*='top-end'] .${tooltipClasses.tooltip} {
      border-radius: 16px 16px 0px 16px;
      .${tooltipClasses.arrow} {
        left: auto !important;
        right: 0px !important;
      }
      .${tooltipClasses.arrow}:after {
        border-right: 12px solid ${({ theme }) => theme.getColor('@clutch/darkText')};
      }
    }
  }
`;
