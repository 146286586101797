import React from 'react';
import styled from 'styled-components';
import LoadingSpinner from '../../LoadingSpinner';

export const ImageDisplay = styled.img`
  width: 100%;
  visibility: ${({ hasLoaded }) => (hasLoaded ? 'visible' : 'hidden')};
  object-fit: cover;
  height: 100%;
`;

export const VehicleImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  position: relative;
  ${({ hasBorderRadius }) => hasBorderRadius && 'border-radius: 6px 6px 0px 0px;'}
  ${({ hasLoaded }) =>
    !hasLoaded &&
    `
      &:before {
        content: ' ';
        display: block;
        width: 100%;
        padding-top: calc((2 / 3) * 100%);
      }
    `}
`;

export const VehicleLoadingSpinner = styled(props => <LoadingSpinner size={15} {...props} />)`
  position: absolute;
  left: 50%;
  top: 50%;
`;

export const VehicleHolidayGraphicImage = styled.img`
  position: absolute;
  z-index: 1000;
  bottom: 0;
  right: 0;
  width: 90px;
  height: auto;

  ${({ theme }) => theme.media.xs`
    width: 50px;
  `}
`;
