import { useBooleanState } from '@clutch/hooks';
import { Stack } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { Button } from 'src/components/molecules/atoms/Button';
import { Modal } from 'src/components/molecules/Modal';
import Scheduler from 'src/components/Scheduler/Scheduler';
import { CheckoutContext } from 'src/contexts';
import { RetailCheckoutContext } from 'src/contexts/retailCheckout';
import { RetailCheckoutSteps } from 'src/contexts/retailCheckout/utils';

const ActivitySlotUnavailableModal = () => {
  const modalState = useBooleanState({ initialState: true });
  const { onContinue, checkout, isContinueLoading, receptionTypes, isReceptionTypesLoading, reloadReceptionTypes } =
    useContext(RetailCheckoutContext);
  const { setActiveErrorModal, activeErrorModal } = useContext(CheckoutContext);

  const [selectedLocation, setSelectedLocation] = useState(receptionTypes?.pickup?.pickupLocations?.[0]);
  const [dayTimeSlotMap, setDayTimeSlotMap] = useState({});
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');

  const handleOnSaveClick = async () => {
    const payload = { date, time, locationId: selectedLocation.location.id };
    await onContinue({
      payload,
      trackingPayload: { scheduledAt: payload },
      stepKey: RetailCheckoutSteps.RECEPTION_TIME,
      ignoreNextStep: true,
    });
    setActiveErrorModal(null);
  };

  const onClose = () => {
    if (!modalState.value && activeErrorModal) {
      modalState.setFalse();
    }
    setActiveErrorModal(null);
  };

  // On modal open, reload reception types to ensure we have the latest data
  useEffect(() => {
    reloadReceptionTypes();
  }, []);

  useEffect(() => {
    if (!activeErrorModal) {
      modalState.setFalse();
    }
  }, [activeErrorModal]);

  useEffect(() => {
    if (!isReceptionTypesLoading && receptionTypes?.pickup?.pickupLocations?.length) {
      // Prioritize the current selected location from the checkout context;
      // fallback to the nearest pickup location otherwise.
      const closestPickupLocation = receptionTypes.pickup.pickupLocations[0];

      const pickupLocation = checkout?.deliveryDetails?.location
        ? receptionTypes.pickup.pickupLocations.find(
            pickupLocation => pickupLocation.location.id === checkout?.deliveryDetails?.location.id,
          ) || closestPickupLocation
        : closestPickupLocation;

      setSelectedLocation(pickupLocation);
    }
  }, [isReceptionTypesLoading, receptionTypes?.pickup?.pickupLocations, checkout?.deliveryDetails?.location]);

  useEffect(() => {
    if (selectedLocation) {
      const newDayTimeSlotMap = Object.fromEntries(
        Object.entries(selectedLocation.availability || {}).map(([date, times]) => [
          date,
          (times as string[]).map((time: string) => ({ time, isAvailable: true })),
        ]),
      );
      setDayTimeSlotMap(newDayTimeSlotMap);
    }
  }, [selectedLocation]);

  return (
    <Modal
      open={modalState.value && activeErrorModal}
      onClose={onClose}
      title={activeErrorModal?.title}
      aria-label={activeErrorModal?.title}
    >
      <Stack>
        <Stack sx={muiTheme => ({ width: '881px', [muiTheme.breakpoints.down('xs')]: { width: '100%' } })}>
          <Scheduler
            height={receptionTypes.pickup.pickupLocations.length > 1 ? '340px' : '307px'}
            locationLabel={
              receptionTypes?.pickup?.pickupLocations?.length && receptionTypes.pickup.pickupLocations.length > 1
                ? 'Select a location'
                : 'Location'
            }
            locations={receptionTypes?.pickup?.pickupLocations}
            selectedLocation={selectedLocation}
            dayTimeSlotMap={dayTimeSlotMap}
            selectedDate={date}
            selectedTime={time}
            handleLocationClick={locationId => {
              const newLocation = receptionTypes?.pickup?.pickupLocations?.find(
                (pickupLocation: Record<string, Location | any>) => pickupLocation.location.id === locationId,
              );
              if (newLocation) {
                setSelectedLocation(newLocation);
                setDate('');
                setTime('');
              }
            }}
            handleDateClick={date => setDate(date)}
            handleTimeClick={time => setTime(time)}
            isModal
          />
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mt={4}
          sx={muiTheme => ({ [muiTheme.breakpoints.down('xs')]: { gap: '16px' } })}
        >
          <Button
            color="tertiary"
            onClick={() => setActiveErrorModal(null)}
            sx={muiTheme => ({ width: '255px', [muiTheme.breakpoints.down('xs')]: { width: '100%', height: '56px', fontSize: '20px' } })}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            loading={isContinueLoading || isReceptionTypesLoading}
            onClick={handleOnSaveClick}
            disabled={!date || !time || !selectedLocation}
            sx={muiTheme => ({
              width: '255px',
              [muiTheme.breakpoints.down('xs')]: { width: '100%', height: '56px', fontSize: '20px' },
            })}
          >
            Continue
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ActivitySlotUnavailableModal;
