import { vehicleStates } from '@clutch/clutch-common/lib/constants';
import { useBooleanState } from '@clutch/hooks';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { memo, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import ClutchIcon from '../../components/ClutchIcon';
import { Tooltip } from '../../components/Tooltip';
import { AuthContext, LoginSignupModalContext, VehicleDetailsContext } from '../../contexts';
import { ROUTES } from '../../static';
import Bell from './assets/Bell.svg';
import CheckInCircle from './assets/CheckInCircle.svg';

import * as Styled from './styles/index.styles';

const FavouriteButton = ({
  variant,
  vehicle,
  small,
  onSuccess,
  displayNotifyMeOnly,
  updatePhoneNumberModalState,
  trackNotifyMeSubmitted,
  noMargin,
  buttonWidth,
}) => {
  const loginSignupModalContext = useContext(LoginSignupModalContext);
  const authContext = useContext(AuthContext);
  const vehicleDetailsContext = useContext(VehicleDetailsContext);
  const favouriteButtonFilledState = useBooleanState();
  const history = useHistory();

  const favouriteButtonVehicle = vehicle || vehicleDetailsContext.vehicle;
  const isComingSoon = favouriteButtonVehicle.websiteState === vehicleStates.COMING_SOON;
  const isReserved = favouriteButtonVehicle.websiteState === vehicleStates.SALE_PENDING;
  const isFavourited = authContext.isAuthenticated && authContext?.user?.likes?.includes(favouriteButtonVehicle.id);

  useEffect(() => {
    !loginSignupModalContext.modalOpenState.value && favouriteButtonFilledState.setState(isFavourited);
  }, [isFavourited, loginSignupModalContext.modalOpenState.value]);

  const WrapperComponent = Styled.getWrapperComponent(variant);

  const addFavouritedVehicleToUserProfile = async () => {
    await authContext.addFavourite(favouriteButtonVehicle.id);
  };

  const unfavouriteVehicle = async () => {
    if (authContext.isAuthenticated) {
      await authContext.removeFavourite(favouriteButtonVehicle.id);
      await onSuccess();
    }
  };

  const favouriteVehicleIfAuthenticated = async () => {
    if (authContext.isAuthenticated) {
      addFavouritedVehicleToUserProfile(favouriteButtonVehicle.id);
      await onSuccess();
    } else {
      loginSignupModalContext.onModalOpen();
    }
  };

  const favouriteNotifiyMeVehicle = async () => {
    if (authContext.isAuthenticated) {
      // logged in user has no phone number on account
      if (!authContext.user.formattedPhoneNumber || authContext.user.formattedPhoneNumber === '') {
        updatePhoneNumberModalState && updatePhoneNumberModalState.setTrue();
        // logged in user has phone number on account
      } else {
        favouriteButtonFilledState.setTrue();
        addFavouritedVehicleToUserProfile(favouriteButtonVehicle.id);
        trackNotifyMeSubmitted();
        await onSuccess();
      }
      // user not logged in, force login/signup
    } else {
      loginSignupModalContext.loginOrSignupToNotify(isReserved);
      authContext.setAuthOnSuccessPayload({
        type: 'VDP_NOTIFICATION',
      });
      loginSignupModalContext.onModalOpen({
        shouldRedirectOnSignIn: true,
      });
    }
  };

  useEffect(() => {
    if (
      authContext.isAuthenticated &&
      favouriteButtonFilledState.value &&
      authContext.user?.likes &&
      !authContext.user.likes.includes(favouriteButtonVehicle.id) &&
      favouriteButtonVehicle.websiteState !== vehicleStates.COMING_SOON
    ) {
      addFavouritedVehicleToUserProfile();
    }
  }, [authContext.isAuthenticated]);

  const handleFavouriteClick = event => {
    event.stopPropagation();
    event.preventDefault();
    if (!isFavourited) {
      if (isComingSoon || isReserved) {
        favouriteNotifiyMeVehicle();
      } else {
        favouriteButtonFilledState.setTrue();
        favouriteVehicleIfAuthenticated();
      }
    } else {
      favouriteButtonFilledState.setFalse();
      unfavouriteVehicle();
    }
  };

  return (isComingSoon || isReserved) && displayNotifyMeOnly ? (
    favouriteButtonFilledState.value ? (
      <Tooltip
        title={
          <>
            <Styled.PopoverText>Notifications for this vehicle are on. You can update your settings in your account.</Styled.PopoverText>
            <Styled.UpdateaNotificationsButton version="2" onClick={() => history.push(ROUTES.MY_DASHBOARD[9])} noGradient secondary>
              Update notifications
            </Styled.UpdateaNotificationsButton>
          </>
        }
        padding="16px"
        maxWidth="257px"
        borderRadius="8px"
        noWrapperPadding
        zIndex="0"
      >
        <span>
          <Styled.Button
            noMargin={noMargin}
            width={buttonWidth}
            disabled
            version="2"
            isLoading={R.isEmpty(favouriteButtonVehicle)}
            noGradient
            secondary
          >
            {(isComingSoon || isReserved) &&
              (favouriteButtonFilledState.value ? <Styled.Icon src={CheckInCircle} /> : <Styled.Icon src={Bell} />)}
            Notify me
          </Styled.Button>
        </span>
      </Tooltip>
    ) : (
      <Styled.Button
        noMargin={noMargin}
        width={buttonWidth}
        onClick={handleFavouriteClick}
        disabled={favouriteButtonFilledState.value}
        version="2"
        isLoading={R.isEmpty(favouriteButtonVehicle)}
        noGradient
        secondary
      >
        {(isComingSoon || isReserved) &&
          (favouriteButtonFilledState.value ? <Styled.Icon src={CheckInCircle} /> : <Styled.Icon src={Bell} />)}
        Notify me
      </Styled.Button>
    )
  ) : (
    <Styled.FavouriteContainer onClick={handleFavouriteClick}>
      <WrapperComponent isFavourited={favouriteButtonFilledState.value} small={small} aria-label="favourite vehicle button">
        <ClutchIcon size="20px" name="HeartSolid" />
      </WrapperComponent>
    </Styled.FavouriteContainer>
  );
};

FavouriteButton.propTypes = {
  variant: PropTypes.string,
  vehicle: PropTypes.shape({}),
  small: PropTypes.bool,
  onSuccess: PropTypes.func,
  updatePhoneNumberModalState: PropTypes.object,
  displayNotifyMeOnly: PropTypes.bool,
  trackNotifyMeSubmitted: PropTypes.func,
  noMargin: PropTypes.bool,
  buttonWidth: PropTypes.string,
};

FavouriteButton.defaultProps = {
  variant: 'default',
  vehicle: null,
  small: false,
  onSuccess: () => {},
  updatePhoneNumberModalState: null,
  displayNotifyMeOnly: false,
  trackNotifyMeSubmitted: () => {},
  noMargin: false,
  buttonWidth: '304px',
};

export default memo(FavouriteButton);
